import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { GETAPI, POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

const ExportEmployee = () => {
  const [cookie] = useCookies(["user", "companyName"]);
  const [Company, setCompany] = useState("");
  const [AllData, setAllData] = useState([]);
  const [isLoad, setisLoad] = useState(true);

  const handleChangeCompany = async (e) => {
    try {
      setisLoad(false);
      const data = await GETAPI(
        `${Api.Training}?type=${e.target.value}`,
        cookie.user
      );

      if (e.target.value == cookie.companyName)
        setAllData((prev) => data?.data?.Company);
      else setAllData((prev) => data?.data?.MainData);
    } catch (er) {
      console.log(er);
    } finally {
      setisLoad(true);
      setCompany((prev) => e.target.value);
    }
  };

  const handleGetExportData = async (item) => {
    try {
      let obj = { type: Company, id: item?._id };
      const data = await POSTAPI(Api.Export, obj, cookie.user);
      // console.clear();
      let NewData = data?.data;

      NewData = NewData?.map((el) => {
        let newEl = { ...el, training: item?.title };
        newEl["Empid"] = newEl.mainId;
        newEl["EmpName"] = newEl.name;

        delete newEl.image;
        delete newEl.documents;
        delete newEl.password;
        delete newEl.createdAt;
        delete newEl.updatedAt;
        delete newEl.mainId;
        delete newEl.name;
        delete newEl.refId;
        delete newEl.companyId;
        delete newEl.__v;
        delete newEl.location;
        return newEl;
      });

      const keys = Object.keys(NewData[0]);
      const csvContent =
        "data:text/csv;charset=utf-8," +
        keys.join(",") +
        "\n" +
        NewData.map((row) => keys.map((key) => row[key]).join(",")).join("\n");
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${item?.title}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (er) {
    } finally {
      document.getElementById("closeModal").click();
      setCompany("");
      setAllData("");
    }
  };

  return (
    <div
      class="modal fade "
      id="ExportModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog   modal-xl  ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              id="closeModal"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
          </div>

          <form className="comp-edit">
            <div className="setting-click">
              <h5>Export Data</h5>
              <div className="row mx-4 g-3 my-acc-padding">
                <div className=" col-12 mb-3">
                  <label htmlFor="formGroupExampleInput" class="form-label">
                    Role
                  </label>
                  <select
                    type="text"
                    class="form-control"
                    aria-label="Last name"
                    onChange={handleChangeCompany}
                  >
                    <option value="">Select Training </option>
                    <option value="safe4you">safe4you</option>
                    <option value={cookie?.companyName}>
                      {cookie?.companyName}
                    </option>
                  </select>
                </div>
                {!!Company &&
                  isLoad &&
                  (!AllData?.length ? (
                    <div>Training Not Found !</div>
                  ) : (
                    <div className=" col-12 mb-3">
                      <label htmlFor="formGroupExampleInput" class="form-label">
                        Training
                      </label>
                      <div className="row my-3">
                        {AllData?.map((item) => (
                          <div className="col-lg-4 col-md-6 col-6 mb-3">
                            <div className="post-card">
                              <div className="img-card">
                                <div className="postion-btn">
                                  <div
                                    className="in-img post-section-img hover-video-btn"
                                    onClick={() => handleGetExportData(item)}
                                  >
                                    <video src={item?.video}></video>
                                  </div>
                                </div>
                                <div className="insure-txt">
                                  <p className="me-0 text-cut2">
                                    {item?.title}
                                  </p>
                                  <hr />
                                  <div className="graphic-icon">
                                    <img
                                      src={item.userId.image}
                                      className="img-fluid rounded-5  "
                                      alt="logo"
                                      style={{ width: "9%" }}
                                    />
                                    <h6>{item.userId.name}</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExportEmployee;
