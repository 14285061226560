import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import basic from '../Image/post-one.png';
import emi from '../Image/emergency-modal-icon.svg';

const Homedemo = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 3000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {showModal && (
                <div className="modal fade show" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body modal-emergency">
                                <div className='close-modal-btn'>
                                    <button type="button" className="close" aria-label="Close" onClick={() => setShowModal(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className='eme-logo-text'>
                                    <img src={emi} className='img-fluid' alt='S' />
                                    <h3>Emergency Contact</h3>
                                </div>
                                {/* Add your emergency contact content here */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Sidebar />
            <Header />
            <div className="asside home-owl">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-6 col-12'>
                            <div className='basic-bg'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-5 col-md-6 col-12'>
                                        <div className='img-card'>
                                            <div className='postion-btn'>
                                                <div className='in-img'>
                                                    <img src={basic} className='img-fluid' alt='s' />
                                                    <div className='btn-click-to-play'>
                                                        <Link to={'#'} > <i className="fa-solid fa-play"></i> </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Basic first aid Episode 13</h3>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-6 col-12'>
                                        <div className='video-txt'>
                                            <h2>Basic first aid Episode 13</h2>
                                            <p>don't run out of the best NFT this month, you have to collect it soon</p>
                                            <Link to={'#'} type='submit' data-bs-toggle="modal" data-bs-target="#exampleModal">start training now <i className="fa-solid fa-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Homedemo;
