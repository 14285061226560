import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PUTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
import { useCookies } from "react-cookie";

const EditModal = ({ item, update }) => {
  const { register, reset, handleSubmit } = useForm();
  const [cookies] = useCookies(["user"]);
  const handleUpdate = (data) => {
    let obj = {
      id: item?._id,
      service: data?.service || item?.service,
      contact: data?.contact || item?.contact,
    };
    toast
      .promise(PUTAPI(Api.Emergency, obj, cookies.user), {
        pending: "Wait Few Moment !",
        success: "Emergency Detail Update Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        update();
      })
      .catch(() => {});
    document.getElementById("closeEdit").click();
  };
  return (
    <div
      class="modal fade "
      id="demotwo"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered  ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              type="button"
              id="closeEdit"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
          </div>
          <form onSubmit={handleSubmit(handleUpdate)} class="modal-body">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Service Name
              </label>
              <input
                type="text"
                class="form-control"
                defaultValue={item?.service}
                id="exampleFormControlInput1"
                placeholder="Service Name"
                {...register("service")}
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Contact number
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="+91 9876543210"
                defaultValue={item?.contact}
                {...register("contact")}
              />
            </div>
            <div>
              <button type="submit" className="emebtnss">
                <i class="fa-solid fa-pencil me-2"></i>Edit Emergency Contact
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
