import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../Image/user.png";
import NotFound from "../Image/notfound.svg";
import PiaChart from "../components/PiaChart";
import useGetInfo from "../hook/useGetInfo";
import { useCookies } from "react-cookie";
import Api from "../utils/Api";
import { GETAPI } from "../utils/Integration";

const TrainingChart = () => {
  let [cookie] = useCookies(["user", "companyImage", "companyName"]);
  const [Type, setType] = useState(cookie.companyName);
  const [MainData, setMainData] = useState(null);
  const [isUpdate, setisUpdate] = useState(false);
  const { User } = useGetInfo();
  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(`${Api.Quiz}?type=${Type}`, cookie.user);
        setMainData((prev) => data?.data);
      } catch (er) {}
    })();
  }, [isUpdate]);
  return (
    <div className="char-design">
      <div className="d-flex-drop mb-25 align-items-center">
        <div className="drop-txt">
          <h2>Training </h2>
        </div>
        <div className="quiz-point ms-2">
          <select
            class="form-select py-2"
            onChange={(e) => {
              setType((prev) => e.target.value);
              setisUpdate(!isUpdate);
            }}
            aria-label="Default select example"
          >
            <option value="Safe4you">Safe4You Training</option>
            <option value={cookie.companyName} selected>
              {cookie.companyName} Training
            </option>
          </select>
        </div>
      </div>
      {MainData ? (
        <>
          <div className="d-flex-drop mb-25">
            <div className="user-name-img">
              <img
                src={User?.image}
                onError={(e) => (e.currentTarget.src = user)}
                className="img-fluid"
                alt=""
              ></img>
              <div className="user-name-id-training">
                <h5>{User?.name}</h5>
                <p>
                  ID-<span>{User?.mainId}</span>
                </p>
              </div>
            </div>
            <div className="quiz-point">
              <h3>
                {MainData?.RightAnswer}/{MainData?.TotalQuestion}
              </h3>
              <h4>quiz point</h4>
            </div>
          </div>
          <PiaChart
            chartDetail={[
              MainData?.NoOfAttempt,
              MainData?.TotalTraining - MainData?.NoOfAttempt,
            ]}
            ShowDetail={`${MainData?.NoOfAttempt}/${MainData?.TotalTraining}`}
          />
          <div className="quiz-history">
            <Link to={`/EmployeeUpdateMain/${User?._id}`}>
              <i class="fa-regular fa-floppy-disk"></i>Quiz History
            </Link>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center  align-items-center  flex-column ">
          {/* <img src={NotFound} className="w-50 h-50" alt="" /> */}
          <h6>Loding... </h6>
        </div>
      )}
    </div>
  );
};

export default TrainingChart;
