import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";

import uploadpdfc from "../Image/pdf-formate-upload.svg";
import { MdCloudDone } from "react-icons/md";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { GETAPI, POSTAPI, PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";

const FivesSystemUpload = () => {
  const [image, setImage] = useState("");
  const [cookie] = useCookies(["user", "companyName"]);
  const [isLoad, setisLoad] = useState(false);
  const navigate = useNavigate();
  const [ChartDetail, setChartDetail] = useState(null);

  const handleUploadFile = () => {
    if (!image) return toast.error("Pdf Must Be Required !");
    if (!image.type?.includes("pdf"))
      return toast.error("File Type Must Be PDF !");
    if (ChartDetail) {
      const newForm = new FormData();
      newForm.append("id", ChartDetail?._id);
      newForm.append("document", image);

      toast
        .promise(PUTAPI(Api.Chart, newForm, cookie.user), {
          pending: "Wait Few Moment !",
          success: "PDF Update Successfully !",
          error: "Something Wrong !",
        })
        .then(() => {
          navigate("/FivesSystem");
        })
        .catch((er) => {
          console.log(er);
          setImage((prev) => null);
        });
    } else {
      const newForm = new FormData();
      newForm.append("type", "system");
      newForm.append("companyName", cookie.companyName);
      newForm.append("document", image);
      toast
        .promise(POSTAPI(Api.Chart, newForm, cookie.user), {
          pending: "Wait Few Moment ",
          success: "PDF upload successfully !",
          error: "Somthing Wrong !",
        })
        .then(() => {
          navigate("/FivesSystem");
        })
        .catch((er) => {
          console.log(er);
          setImage((prev) => null);
        });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Chart}/${cookie.companyName}?type=system`,
          cookie.user
        );
        setChartDetail((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);

  return (
    <div>
      <div className="asside-two">
        <div className="container-fluid">
          <Header />
          {!isLoad ? (
            <div className="d-flex justify-content-center  align-items-center  my-4 ">
              <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#0841D4"
                secondaryColor="#0841D4"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
              />
            </div>
          ) : (
            <>
              <div className="back-post-section">
                <Link to={"/FivesSystem"} type="submit">
                  {" "}
                  <i class="fa-solid fa-arrow-left"></i>{" "}
                </Link>
                <h4>{ChartDetail ? "Update" : "Add New"} 5s System Chart</h4>
              </div>
              <div className="row justify-content-between ">
                <div className="col-lg-12 col-md-12">
                  <div className="commen-layout-upload">
                    <div className="profile-upload-img-two">
                      <div className="pdf-pic">
                        <img src={uploadpdfc} alt="" />
                      </div>
                      <label
                        htmlFor="pdf"
                        className="uplaod-pdf p-0 bg-transparent"
                      >
                        <input
                          type="file"
                          name=""
                          onChange={(e) =>
                            setImage((prev) => e.target.files[0])
                          }
                          id="pdf"
                          hidden
                        />
                        <div
                          className={`btn btn-upload-pdf ${
                            image && "text-bg-success"
                          }  d-flex align-items-center gap-2`}
                        >
                          {image && <MdCloudDone />}
                          <span>Upload PDF</span>
                        </div>
                      </label>
                    </div>
                    {/* <div class="col-12 mb-3 mt-3">
                    <label
                      for="exampleFormControlInput1"
                      class="form-label frm-label mt-0"
                    >
                      About Organization chart
                    </label>
                    <textarea
                      class="form-control "
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="First Floor, Nilkanth Complex, Virani Circle, above "
                    ></textarea>
                  </div> */}
                    <div class="col-12 mb-3 w-100">
                      <button
                        class=" update-plan-btn w-100 "
                        onClick={handleUploadFile}
                      >
                        {ChartDetail ? "Update" : "Add New"} 5s System Chart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FivesSystemUpload;
