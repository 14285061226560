import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import { GETAPI, POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

function Quiz() {
  const { id } = useParams();
  const [AllQuiz, setAllQuiz] = useState([]);
  const [QuestionNo, setQuestionNo] = useState(0);
  const [RightAnswer, setRightAnswer] = useState("");
  const [CurrectAnswer, setCurrectAnswer] = useState(0);
  const [CheckValue, setCheckValue] = useState("");
  const op1 = useRef(null);
  const op2 = useRef(null);
  const op3 = useRef(null);
  const op4 = useRef(null);
  const [SubmitAnswer, setSubmitAnswer] = useState("");
  const [isDisble, setisDisble] = useState(false);
  const [cookie] = useCookies(["user"]);
  const navigate = useNavigate();

  const handleAddAnswer = (e) => {
    const { value } = e.target;
    setCheckValue((prev) => value);
  };

  const handleCheckAnswer = () => {
    if (!CheckValue) return toast.error("Please Select Option !");
    setSubmitAnswer((prev) => CheckValue);
    setisDisble((prev) => true);
    if (CheckValue == RightAnswer) setCurrectAnswer((prev) => prev + 1);
  };

  const handleNextQuestion = async () => {
    try {
      if (QuestionNo + 1 >= AllQuiz.length) {
        toast(`Your Score ${CurrectAnswer}/${AllQuiz?.length}`, {
          theme: "light",
        });
        await POSTAPI(
          Api.Quiz,
          {
            id,
            score: `${CurrectAnswer}/${AllQuiz?.length}`,
          },
          cookie.user
        );
        navigate(`/QuizResult/${id}`);
      } else {
        setisDisble(false);
        setSubmitAnswer((prev) => null);
        setRightAnswer((prev) => AllQuiz[QuestionNo + 1].correct_ans);
        setQuestionNo((prev) => QuestionNo + 1);
        op1.current.checked = false;
        op2.current.checked = false;
        op3.current.checked = false;
        op4.current.checked = false;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCheckValue((prev) => "");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(`${Api.Training}/${id}`, cookie.user);
        setAllQuiz(data.data.questions);
        setRightAnswer(data.data.questions?.[0].correct_ans);
      } catch (er) {}
    })();
  }, [id]);
  return (
    <div>
      <Header />

      <div className="asside-two">
        {AllQuiz?.length ? (
          <div className="container">
            <div className="question-no">
              <h3>Q-{QuestionNo + 1}</h3>
              <h4>{AllQuiz[QuestionNo].question}</h4>
            </div>
            <div
              className={`form-check frm-check mb-3 bg-blue-check ${
                SubmitAnswer == AllQuiz[QuestionNo].ans1 &&
                SubmitAnswer != RightAnswer &&
                "bg-red bg-red-check"
              }
              ${
                SubmitAnswer == AllQuiz[QuestionNo].ans1 &&
                SubmitAnswer == RightAnswer &&
                "bg-green bg-green-check"
              }
              ${
                RightAnswer == AllQuiz[QuestionNo].ans1 &&
                SubmitAnswer &&
                "bg-green bg-green-check"
              }
              `}
            >
              <input
                className={`form-check-input frn-check-input ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans1 &&
                  SubmitAnswer != RightAnswer &&
                  "bg-red"
                }  
                ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans1 &&
                  SubmitAnswer == RightAnswer &&
                  "bg-green "
                }
                
                ${
                  RightAnswer == AllQuiz[QuestionNo].ans1 &&
                  SubmitAnswer &&
                  "bg-green "
                }`}
                type="radio"
                disabled={isDisble}
                ref={op1}
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value={AllQuiz[QuestionNo].ans1}
                onChange={handleAddAnswer}
              />
              <label
                className="form-check-label frn-check-input"
                htmlFor="flexRadioDefault1"
              >
                A.{AllQuiz[QuestionNo].ans1}
              </label>
            </div>
            <div
              className={`form-check frm-check mb-3 bg-blue-check ${
                SubmitAnswer == AllQuiz[QuestionNo].ans2 &&
                SubmitAnswer != RightAnswer &&
                "bg-red bg-red-check"
              }
              ${
                SubmitAnswer == AllQuiz[QuestionNo].ans2 &&
                SubmitAnswer == RightAnswer &&
                "bg-green bg-green-check"
              }
              ${
                RightAnswer == AllQuiz[QuestionNo].ans2 &&
                SubmitAnswer &&
                "bg-green bg-green-check"
              }
              `}
            >
              <input
                className={`form-check-input frn-check-input ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans2 &&
                  SubmitAnswer != RightAnswer &&
                  "bg-red"
                }
                ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans2 &&
                  SubmitAnswer == RightAnswer &&
                  "bg-green"
                }
                ${
                  RightAnswer == AllQuiz[QuestionNo].ans2 &&
                  SubmitAnswer &&
                  "bg-green"
                }
                `}
                type="radio"
                disabled={isDisble}
                name="flexRadioDefault"
                id="flexRadioDefault2"
                ref={op2}
                value={AllQuiz[QuestionNo].ans2}
                onChange={handleAddAnswer}
              />
              <label
                className="form-check-label frn-check-input"
                htmlFor="flexRadioDefault2"
              >
                B. {AllQuiz[QuestionNo].ans2}
              </label>
            </div>
            <div
              className={`form-check frm-check mb-3 bg-blue-check ${
                SubmitAnswer == AllQuiz[QuestionNo].ans3 &&
                SubmitAnswer != RightAnswer &&
                "bg-red bg-red-check"
              }
              ${
                SubmitAnswer == AllQuiz[QuestionNo].ans3 &&
                SubmitAnswer == RightAnswer &&
                "bg-green bg-green-check"
              }
              ${
                RightAnswer == AllQuiz[QuestionNo].ans3 &&
                SubmitAnswer &&
                "bg-green bg-green-check"
              }
              `}
            >
              <input
                className={`form-check-input frn-check-input ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans3 &&
                  SubmitAnswer != RightAnswer &&
                  "bg-red"
                } 
                ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans3 &&
                  SubmitAnswer == RightAnswer &&
                  "bg-green "
                }
                ${
                  RightAnswer == AllQuiz[QuestionNo].ans3 &&
                  SubmitAnswer &&
                  "bg-green "
                }
                `}
                ref={op3}
                type="radio"
                disabled={isDisble}
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value={AllQuiz[QuestionNo].ans3}
                onChange={handleAddAnswer}
              />
              <label
                className="form-check-label frn-check-input"
                htmlFor="flexRadioDefault3"
              >
                C. {AllQuiz[QuestionNo].ans3}
              </label>
            </div>
            <div
              className={`form-check frm-check mb-3 bg-blue-check ${
                SubmitAnswer == AllQuiz[QuestionNo].ans4 &&
                SubmitAnswer != RightAnswer &&
                "bg-red bg-red-check"
              } 
              ${
                SubmitAnswer == AllQuiz[QuestionNo].ans4 &&
                SubmitAnswer == RightAnswer &&
                "bg-green bg-green-check"
              }
              ${
                RightAnswer == AllQuiz[QuestionNo].ans4 &&
                SubmitAnswer &&
                "bg-green bg-green-check"
              }
              `}
            >
              <input
                className={`form-check-input frn-check-input ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans4 &&
                  SubmitAnswer != RightAnswer &&
                  "bg-red"
                }
                ${
                  SubmitAnswer == AllQuiz[QuestionNo].ans4 &&
                  SubmitAnswer == RightAnswer &&
                  "bg-green "
                }
                ${
                  RightAnswer == AllQuiz[QuestionNo].ans4 &&
                  SubmitAnswer &&
                  "bg-green "
                }
                `}
                type="radio"
                ref={op4}
                disabled={isDisble}
                name="flexRadioDefault"
                id="flexRadioDefault4"
                value={AllQuiz[QuestionNo].ans4}
                onChange={handleAddAnswer}
              />
              <label
                className="form-check-label frn-check-input"
                htmlFor="flexRadioDefault4"
              >
                D.{AllQuiz[QuestionNo].ans4}
              </label>
            </div>
            <div className="quizsub-btn d-flex  align-items-center  gap-2">
              {!isDisble ? (
                <button onClick={handleCheckAnswer}>Check</button>
              ) : (
                <button onClick={handleNextQuestion}>
                  Next question<i class="fa-solid fa-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default Quiz;
