import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import sapleshLogo from "../../src/Image/spaleshScreenImg.svg";
import { useCookies } from "react-cookie";
const SplashScreen = () => {
  const [cookie] = useCookies(["user"]);
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (cookie?.user) return window.location.replace("/Home");
      window.location.replace("/Signin");
    }, 2000);

    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <div className="splashScreen-Background">
      <div className="container">
        <div className="row vh-100 d-flex pt-5 justify-content-center ">
          <div className="col-lg-6 col-md-6 col-6 ">
            <div className="d-flex splash-height  flex-column">
              <img
                src={sapleshLogo}
                className="img-fluid h-75"
                alt="Splash Screen Logo"
              />
              <Link
                to={"https://mechodal.com/"}
                className="text-center text-light fs-1"
              >
                4Born
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
