import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import java from "../Image/user.png";
import { useState } from "react";
import useGetInfo from "../hook/useGetInfo";
import { MutatingDots } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import Certificate from "../common/Certificate";
import html2pdf from "html2pdf.js";

function Profile() {
  const { isLoad, User } = useGetInfo();
  const [Document, setDocument] = useState(null);
  const [cookie] = useCookies(["user"]);
  const [Title, setTitle] = useState("");
  const [CreatedAt, setCreatedAt] = useState("");

  const handleDownloadCertificate = (item) => {
    setTitle((prev) => item?.trainingId?.title);
    setCreatedAt((prev) => item?.createdAt);
    const element = document.getElementById("CertificatePDF");
    html2pdf(element, {
      margin: 0.2,
      filename: "Certificate.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        orientation: "p",
        unit: "in",
        format: "a3",
        putOnlyUsedFonts: true,
        // floatPrecision: 16,
      },
    });
  };
  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(Api.UserCerificate, cookie.user);
        setDocument((prev) => data?.data);
      } catch (er) {
        console.log(er);
      }
    })();
  }, []);
  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/Home"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Company Profile</h4>
            </div>
          </div>
        </div>
      </div>
      {/**Profile Section Start */}
      {!isLoad ? (
        <div className="d-flex justify-content-center  align-items-center  h-75  my-5 ">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0841D4"
            secondaryColor="#0841D4"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
          />
        </div>
      ) : (
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-12 profile-section-back-design">
              <div className="jus-end">
                <Link to={"/EditProfile"}>
                  <div className="profile-setting ">
                    <i class="fa-solid fa-gear"></i>
                  </div>
                </Link>
              </div>
              <div className="pro-padding-f">
                <div className="profile-upload-img">
                  <img
                    src={User?.image}
                    onError={(e) => (e.currentTarget.src = java)}
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="profiletext">
                  <h3>{User?.name}</h3>
                  <h4>{User?.mainId}</h4>
                </div>
                <div className="profile-info">
                  <h3>Information</h3>
                  <div className="infor-area ">
                    <h4>Designation:</h4>
                    <input
                      class="form-control"
                      type="text"
                      placeholder={
                        User?.designation == "undefined"
                          ? "-"
                          : User?.designation
                      }
                      aria-label="default input example"
                      readOnly
                    />
                  </div>
                  <div className="infor-area ">
                    <h4>Department:</h4>
                    <input
                      class="form-control"
                      type="text"
                      placeholder={
                        User?.department == "undefined" ? "-" : User?.department
                      }
                      aria-label="default input example"
                      readOnly
                    />
                  </div>

                  <div className="infor-area align-items-start">
                    <h4>Location:</h4>
                    <textarea
                      class="form-control ms-4"
                      id="exampleFormControlTextarea1"
                      placeholder={
                        User?.location == "undefined" ? "-" : User?.location
                      }
                      rows="3"
                      readOnly
                    ></textarea>
                  </div>
                  <h3>Documents</h3>
                </div>

                {Document?.map((el, index) => (
                  <div className="quiz-document mb-3">
                    <div className="ivon-docu">
                      <i class="fa-regular fa-file"></i>
                      <div className="text-document">
                        <h3>
                          {el?.trainingId?.title} {index + 1}
                        </h3>
                        {/* <h4>1.23 MB</h4> */}
                      </div>
                    </div>
                    <div className="download-btn-certificate">
                      <Link onClick={() => handleDownloadCertificate(el)}>
                        <i class="fa-regular fa-circle-down"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <Certificate user={User?.name} date={CreatedAt} title={Title} />
      {/**Profile Section End */}
    </div>
  );
}

export default Profile;
