import React from "react";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { Navigate, useNavigate } from "react-router-dom";
const useGetInfo = () => {
  const [cookie] = useCookies(["user"]);
  const [User, setUser] = React.useState(null);
  const [isLoad, setisLoad] = React.useState(false);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!cookie.user) return navigate("/Signin");
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(Api.User, cookie.user);
        setUser((prev) => data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return { isLoad, User };
};

export default useGetInfo;
