import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import quizresult from "../Image/quizresult.svg";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";

function QuizResult() {
  const { id } = useParams();
  const [cookie] = useCookies(["user"]);
  const [Data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(`${Api.Quiz}/${id}`, cookie.user);
        let [mainObj] = data.data;
        setData((prev) => mainObj);
      } catch (er) {}
    })();
  }, []);
  return (
    <div className="asside-two mb-0">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-12">
            <div className="back-post-section">
              <Link to={"/Home"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Go to Home</h4>
            </div>
            <div className="quiz-result-page">
              <img src={quizresult} className="img-fluid"></img>
              <div className="result-marks">
                <p>Total mark you got from last Exercise</p>
                <h4>
                  <span>{Data?.score}</span>
                  {/* <span>2</span>/<span>3</span> */}
                </h4>
                <Link to={"/Home"} className="btn btn-quizeresult">
                  Go To Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizResult;
