import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { PUTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
import { useCookies } from "react-cookie";

const UpdateModal = ({ id, item, handleUpdate }) => {
  const { setValue, register, reset, handleSubmit } = useForm();
  const [cookie] = useCookies(["user"]);
  const handleUpdatePoint = (data) => {
    let obj = {
      mainId: id,
      id: item?._id,
      ...data,
    };
    toast
      .promise(PUTAPI(Api.PlanPoint, obj, cookie.user), {
        pending: "Loading....",
        success: "Update Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        handleUpdate();
      })
      .catch((er) => {
        console.log(er);
      });
    document.getElementById("CloseUpdateModal").click();
  };

  useEffect(() => {
    setValue("title", item?.title);
    setValue("detail", item?.detail);
  }, [item]);
  return (
    <div
      class="modal fade "
      id="editpoint"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered  ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              id="CloseUpdateModal"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
          </div>
          <form onSubmit={handleSubmit(handleUpdatePoint)} class="modal-body">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Edit Title
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Point"
                {...register("title")}
              />
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Edit Description
              </label>
              <textarea
                rows="4"
                className="form-control"
                {...register("detail")}
              ></textarea>
            </div>
            <div class="mb-3">
              <button type="submit" className="emebtnss">
                <i class="fa-solid fa-plus me-2"></i>Edit Point
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
