import React from "react";
import { useForm } from "react-hook-form";
import useGetInfo from "../../hook/useGetInfo";
import { POSTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";

const Form = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { User } = useGetInfo();
  const [cookies] = useCookies(["user"]);
  const handleAddWork = (data) => {
    toast
      .promise(
        POSTAPI(
          Api.WorkSpace,
          { ...data, type: "kaizen", id: User?._id },
          cookies.user
        ),
        {
          pending: "Wait Few Moment !",
          success: "Kaizen Miss Add Successfully !",
          error: "Something Went Wrong !",
        }
      )
      .then(() => {})
      .catch((er) => {
        console.log(er);
      });
    reset();
  };
  return (
    <form
      onSubmit={handleSubmit(handleAddWork)}
      className="container bg-white p-5 rounded-5 "
    >
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Employ Code
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder={User?.mainId}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Employee Name
            </label>
            <input
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder={User?.name}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Department
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Manager Directore in Graphic designer...."
              {...register("department", { required: true })}
            ></textarea>
            {errors?.detail1 && (
              <p className="p-1 " style={{ color: "red" }}>
                Please Enter Department!
              </p>
            )}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Kaizen Miss Detail Writing{" "}
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make "
              {...register("detail1", { required: true })}
            ></textarea>
            {errors?.detail1 && (
              <p className="p-1 " style={{ color: "red" }}>
                Please Enter Kaizen Miss Detail Writing !
              </p>
            )}
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              Kaizen Miss Detail Writing{" "}
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500 when an unknown printer took a galley of type and scrambled it to make "
              {...register("detail2", { required: true })}
            ></textarea>
            {errors?.detail2 && (
              <p className="p-1 " style={{ color: "red" }}>
                Please Enter Kaizen Miss Detail Writing !
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 nearsubmit-btn">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default Form;
