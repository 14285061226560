import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
const Signin = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies([
    "user",
    "role",
    "companyImage",
    "companyName",
  ]);
  if (cookie.token) return <Navigate to={"/Home"} replace={true} />;
  const handleLogin = (data) => {
    toast
      .promise(POSTAPI(Api.LoginApi, data), {
        pending: "Wait Few Moment !",
        success: "Login Successfully !",
        error: "Employee Id or Password Wrong !",
      })
      .then((val) => {
        setCookie("user", val.token);
        setCookie("role", val.role);
        setCookie("companyImage", val.companyImage);
        setCookie("companyName", val.companyName);
        navigate("/Home");
      })
      .catch(() => {});
    reset();
  };
  return (
    <div className="bg-login">
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <div className="top-login-text">
                <h2>Log In</h2>
                <p>Please sign-in to your account and start the adventure</p>
              </div>
              <form onSubmit={handleSubmit(handleLogin)}>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("mainId", { required: true })}
                  />
                  <label htmlFor="floatingInput">Employee Code</label>
                  {errors?.mainId && (
                    <p className="p-2 " style={{ color: "red" }}>
                      Please Enter Employee Code !
                    </p>
                  )}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("password", { required: true })}
                  />
                  <label htmlFor="floatingPassword">Password</label>
                  {errors?.password && (
                    <p className="p-2 " style={{ color: "red" }}>
                      Please Enter Password !
                    </p>
                  )}
                </div>
                <button type="submit" className="btn btn-login">
                  Log In
                </button>
                <Link to={"/visitorpeople"} className="login-as-visitor">
                  Login As Visitor
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
