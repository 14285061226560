import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";

function Notification() {
  const [isLoad, setisLoad] = useState(false);
  const [MainData, setMainData] = useState([]);
  const [cookie] = useCookies(["user"]);
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(Api.Notification, cookie.user);
        console.log(data);
        setMainData(data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div className="asside-two ">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-lg-5 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/Home"} type="submit">
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Notification</h4>
            </div>
          </div>
        </div>
        {!isLoad ? (
          <div
            className="d-flex justify-content-center align-items-center flex-column  "
            style={{ height: "70vh" }}
          >
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : !MainData?.length ? (
          <div>
            <h2>Notification Not Found !</h2>
          </div>
        ) : (
          MainData?.map((item) => (
            <>
              {item?.type == "success" && (
                <div className="notfi-task mb-2">
                  <div className="circle-green">
                    <i class="fa-solid fa-check"></i>
                  </div>
                  <div className="task-text">
                    <h3>Success </h3>
                    <p>{item?.message}</p>
                  </div>
                </div>
              )}
              {item?.type == "error" && (
                <div className="notfi-task mb-2 red-bg">
                  <div className="circle-green red-bg-to">
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                  <div className="task-text">
                    <h3>Error </h3>
                    <p>{item?.message}</p>
                  </div>
                </div>
              )}
              {item?.type == "warning" && (
                <div className="notfi-task mb-2 yelllow-bg">
                  <div className="circle-green yelllow-bg-to">
                    <i class="fa-solid fa-exclamation"></i>
                  </div>
                  <div className="task-text">
                    <h3>Warning </h3>
                    <p>{item?.message}</p>
                  </div>
                </div>
              )}
            </>
          ))
        )}
      </div>
    </div>
  );
}

export default Notification;
