import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer>
        <div className="foorer-sec">
          <p>© 2024 Made With <i className="fa-regular fa-heart"></i> By <Link to={'/Home'}>Safe4you</Link></p>
          <p>Presented By <Link to={'/Home'}>Safe4you</Link></p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
