import React from 'react'
import { Link } from 'react-router-dom';
import Header from './Header';
import basic from '../Image/post-one.png';
import graphicicon from '../Image/graphic-icon.svg';
import doc from '../Image/doc.svg';
import video from '../Image/video.mp4';

const buy = [1, 2, 3, 4]


function VideoPostAbout() {
    return (
        <div className='asside-two'>
            <div className='container-fluid'>
                <Header />
                <div className='row'>

                    <div className='col-lg-5 col-md-12 col-12 mb-3'>
                        <div className='back-post-section'>
                            <Link to={'/Post'} type='submit'> <i class="fa-solid fa-arrow-left"></i> </Link>
                            <h4>Post Section</h4>
                        </div>

                        <div className='post-card-two'>
                            <div className='post-card-two-img'>
                                <video controls>
                                    <source src={video} type="video/mp4" />
                                </video>
                                <div className='heart-rating'>
                                    <h3><i class="fa-solid fa-heart"></i>4.2k</h3>
                                </div>
                            </div>
                            <div className='set-grafic-icon'>
                                <h5>Insure the safety of the children DOC</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into </p>
                                <hr />
                                <div className='graphic-icon'>
                                    <img src={graphicicon} className='img-fluid' alt='logo' />
                                    <h6>Graphiglow design</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-7 col-md-12 col-12'>
                        <div className='postabout-sec-bg'>
                            <div className="pro-add-new px-0">
                                <p>You Also Like This</p>
                                <div class="form-group has-search header-bottom-search post-page-search-bg">
                                    <span class="fa fa-search form-control-feedback"></span>
                                    <input type="text" class="form-control" placeholder="Search" />
                                </div>
                            </div>
                            <div className="all-project-table">
                                {
                                    buy.map(() => {
                                        return (
                                            <div className='post-details-in mb-3 me-2'>
                                                <div className='also-like-this'>
                                                    <div>
                                                        <div className='p-img-set'>
                                                            <img src={doc} className='img-fluid'  alt=''/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='set-grafic-icon p-0 me-3'>
                                                            <div className='d-set-heart-icon'>
                                                                <div className='set-grafic-icon-to'>
                                                                    <h5>Insure the safety of the children DOC</h5>
                                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                            <div className='graphic-icon'>
                                                                <img src={graphicicon} className='img-fluid' alt='logo' />
                                                                <h6>Graphiglow design</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='heart-rating-to'>
                                                        <h3><i class="fa-solid fa-heart"></i>4.2k</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPostAbout