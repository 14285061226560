import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import java from "../../Image/doc-post.svg";
import NotFound from "../../Image/notfound.svg";
import { useState } from "react";
import { GETAPI, POSTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import Papa from "papaparse";
import ExportWorkSpace from "../ExportWorkSpace";
import { toast } from "react-toastify";

const Detail = () => {
  const [AllData, setAllData] = useState([]);
  const [MainData, setMainData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [cookie] = useCookies(["user"]);
  const [isUpdate, setisUpdate] = useState(false);

  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleSearch = (e) => {
    const { value } = e.target;
    const newData = AllData?.filter(
      (el) =>
        el.userId.name.toLowerCase().includes(value.toLowerCase()) ||
        el.userId.mainId.toLowerCase().includes(value.toLowerCase())
    );
    setMainData((prev) => newData);
  };

  const handleImportCSV = (e) => {
    let file = e.target.files[0];
    Papa.parse(file, {
      complete: (result) => {
        const newData = result.data.map((item) => {
          return item;
        });
        const columnNames = newData.shift();

        // Convert the remaining arrays into array of objects
        let arrayOfObjects = newData.map((entry) => {
          const obj = {};
          columnNames.forEach((key, index) => {
            obj[key] = entry[index];
          });
          return obj;
        });
        arrayOfObjects = arrayOfObjects?.filter(
          (el) => !AllData?.some((item) => item?._id == el?._id)
        );
        arrayOfObjects = arrayOfObjects.map((item) => {
          delete item?._id;
          delete item?.createdAt;
          delete item?.updatedAt;
          item["type"] = "near";
          return item;
        });

        if (arrayOfObjects?.length) {
          POSTAPI(Api.ImportWorkSpace, arrayOfObjects, cookie.user)
            .then(() => {
              handleUpdate();
            })
            .catch((er) => {
              console.log(er);
            });
        } else {
          toast.error("Unique Data Not Found !");
        }
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(`${Api.WorkSpace}?type=near`, cookie.user);
        setAllData((prev) => data.data);
        setMainData((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  return (
    <>
      <div className="row  justify-content-between align-items-center">
        <div className="col-lg-5 col-12">
          <div className="back-post-section mb-0">
            <h4 className="ms-0">Near Miss </h4>
          </div>
        </div>
        <div className="col-lg-7 col-12">
          <div className="pro-add-new mb-0 px-0 mt-lg-0 mt-3">
            <div class="form-group has-search header-bottom-search post-page-search-bg">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Number "
                onChange={handleSearch}
              />
            </div>
            <div className="d-flex align-items-center  gap-4">
              <Link
                onClick={() => {
                  document.getElementById("Import").click();
                }}
              >
                Import
              </Link>
              <label htmlFor="importData" id="Import" hidden></label>
              <input
                type="file"
                id="importData"
                onChange={handleImportCSV}
                hidden
              />
              <Link data-bs-toggle="modal" data-bs-target="#ExportWorkSpace">
                Export
              </Link>
            </div>
          </div>
        </div>
        {/*<div className="col-lg-6 col-12">
                        <div class="form-group has-search header-bottom-search">
                            <span class="fa fa-search form-control-feedback"></span>
                            <input type="text" class="form-control" placeholder="Search" />
                        </div>
                    </div>*/}
      </div>
      <div className="con-back-bg bg-transparent p-0">
        {!isLoad ? (
          <div className="d-flex justify-content-center  align-items-center  h-50 my-5">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : !MainData?.length ? (
          <div className="d-flex flex-column   justify-content-center  align-items-center my-5">
            <img src={NotFound} className="w-25 h-25 img-fluid " alt="" />
            <h6>Near Miss Not Found !</h6>
          </div>
        ) : (
          MainData?.map((item) => (
            <div className="community-user mt-0">
              <div className="user-commen">
                <div className="d-flex align-items-center">
                  <div className="user-coomen-img">
                    <img
                      src={item?.userId?.image}
                      onError={(e) => (e.currentTarget.src = java)}
                      className="imgfluid"
                      alt=""
                    ></img>
                  </div>
                  <div className="user-commen-text">
                    <h3>
                      {item?.userId?.name} ({item?.userId?.mainId})
                      {!item?.isWatch && (
                        <span className="spl-for-new">New</span>
                      )}
                    </h3>
                    <h4 className="text-cut">{item?.detail1}</h4>
                  </div>
                </div>
              </div>

              <div className="view-s-btn ms-lg-3 ms-2">
                <Link to={`/NearMiss/${item._id}`}>View</Link>
              </div>
            </div>
          ))
        )}
        <ExportWorkSpace data={AllData} />
      </div>
    </>
  );
};

export default Detail;
