import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { POSTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
const AddModal = ({ update }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [cookies] = useCookies(["user", "companyName"]);
  const handleAddData = (data) => {
    toast
      .promise(
        POSTAPI(
          Api.Emergency,
          { ...data, company: cookies.companyName },
          cookies.user
        ),
        {
          pending: "Wait Few Moment !",
          success: "Emergency Add Successfully !",
          error: "Something Went Wrong !",
        }
      )
      .then(() => {
        update();
      })
      .catch((er) => {});
    reset();
    document.getElementById("closeModal").click();
  };
  return (
    <div
      class="modal fade "
      id="demoone"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered  ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              type="button"
              id="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
          </div>
          <form onSubmit={handleSubmit(handleAddData)} class="modal-body">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Service Name
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Ambulance"
                {...register("service", { required: true })}
              />
              {errors?.service && (
                <p className="p-1" style={{ color: "red" }}>
                  Please Enter Service !
                </p>
              )}
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Contact number
              </label>
              <input
                type="number"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="+91 9876543210"
                {...register("contact", { required: true })}
              />
              {errors?.contact && (
                <p className="p-1" style={{ color: "red" }}>
                  Please Enter Contact !
                </p>
              )}
            </div>
            <div>
              <button type="submit" className="emebtnss mx-auto ">
                <i class="fa-solid fa-plus me-2"></i>Add New Emergency Contact
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
