import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Link, useParams } from "react-router-dom";
import java from "../Image/doc-post.svg";
import process from "../Image/process.svg";
import { MutatingDots } from "react-loader-spinner";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";

const NearMiss = () => {
  const { id } = useParams();
  const [isLoad, setisLoad] = useState(false);
  const [MainData, setMainData] = useState(null);
  const [cookie] = useCookies(["user"]);
  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(`${Api.WorkSpace}/${id}`, cookie.user);
        setMainData((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div className="asside-two">
      <div className="container-fluid">
        {/* {<Sidebar />} */}
        {<Header />}

        <div className="row  justify-content-between align-items-center">
          <div className="col-lg-6 col-12">
            <div className="back-post-section mb-0">
              <Link to={"/NearMissMain"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Employee name near miss </h4>
            </div>
          </div>
        </div>
        {!isLoad ? (
          <div className="d-flex justify-content-center  align-items-center ">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : (
          <div className="container">
            <div className="con-back-bg">
              <div className="d-flex align-items-center">
                <div className="employee-one-profile">
                  <img
                    src={MainData?.userId?.image}
                    onError={(e) => (e.currentTarget.src = java)}
                    className="img-fluid"
                    alt=""
                  ></img>
                </div>
                <div>
                  <div className="employee-detail-one ">
                    <h3>Employee Name : </h3>
                    <h4>{MainData?.userId?.name}</h4>
                  </div>
                  <div className="employee-detail-one ">
                    <h3>Employ Code :</h3>
                    <h4>{MainData?.userId?.mainId}</h4>
                  </div>
                  <div className="employee-detail-one mb-0">
                    <h3>Department :</h3>
                    <h4>{MainData?.department}</h4>
                  </div>
                </div>
              </div>
              <div className="row detail-margin-top justify-content-between align-items-center">
                <div className="col-lg-5 col-12 ">
                  <div className="detail-writing">
                    <h3>Near miss Detail Writing</h3>
                    <p>{MainData?.detail1}</p>
                  </div>
                </div>
                <div className="col-lg-2 col-12 process-before my-3">
                  <img src={process} className="img-fluid" alt=""></img>
                </div>
                <div className="col-lg-5 col-12">
                  <div className="detail-writing">
                    <h3>Near miss Detail Writing</h3>
                    <p>{MainData?.detail2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NearMiss;
