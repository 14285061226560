import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const Piechart = ({
  chartDetail = [1, 1],
  ChartName = ["Attempt", "Not Attempt"],
  ClinetColors = ["rgba(3, 120, 71, 1)", "rgba(255, 75, 75, 1)"],
  ShowDetail,
}) => {
  // State variable to hold the options
  const [options, setOptions] = useState(() => generateOptions(ShowDetail));

  // Function to generate options based on ShowDetail
  function generateOptions(showDetail) {
    return {
      labels: ChartName,
      colors: ClinetColors,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: "14px",
                fontWeight: 600,
                color: undefined,
              },
              value: {
                show: true,
                fontSize: "25px",
                fontWeight: 700,
                color: "rgba(3, 120, 71, 1)",
                offsetY: 16,
              },
              total: {
                show: true,
                label: "Total",
                color: "#000",
                fontSize: "18px",
                fontWeight: 600,
                formatter: function (w) {
                  return showDetail || "0";
                },
              },
            },
          },
        },
      },
      legend: {
        show: true,
        position: "bottom", // Change legend position to 'right', 'bottom', 'top', or 'left'
        horizontalAlign: "left", // Align legend horizontally
        width: "100px",
        floating: false,
        formatter: function (val, opts) {
          return ` ${val}  ${opts.w.globals.series[opts.seriesIndex]}`; // Add custom legend formatter
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val, { seriesIndex }) {
            return `${ChartName[seriesIndex]}: <br> ${val} Points`; // Add custom tooltip to show points with new line
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };
  }

  // Update options when ShowDetail changes
  useEffect(() => {
    setOptions(generateOptions(ShowDetail));
  }, [ShowDetail]);

  return (
    <div className="donut chart-t-b">
      <Chart options={options} series={chartDetail} type="donut" width="100%" />
    </div>
  );
};

export default Piechart;
