import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PieChart from "./PiaChart";

import NotFound from "../Image/notfound.svg";
import user from "../Image/user.png";
import { GETAPI, POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";

function convertToSimpleDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function EmployeeUpdateMain() {
  const { id } = useParams();
  const { Empid } = useParams();
  let [cookie] = useCookies(["user", "companyName"]);
  const [isLoad, setisLoad] = useState(false);
  const [MainData, setMainData] = useState(null);

  const handleSendNotification = () => {
    toast
      .promise(
        POSTAPI(
          Api.Notification,
          {
            userId: id,
            type: "warning",
            message:
              "Please view training. Essential for growth. Don't skip. Invest in yourself. You won't regret it",
          },
          cookie.user
        ),
        {
          pending: "Loading ...",
          success: "Notification Send Successfully !",
          error: "Something Went Wrong !",
        }
      )
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Quiz}?type=${cookie?.companyName}&id=${id}`,
          cookie?.user
        );
        setMainData(data?.data);
      } catch (er) {
        console.error("Error fetching data:", er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [id]);

  console.log(MainData);
  console.log("Employee ID" + id);

  const renderContent = () => {
    if (!isLoad) {
      return (
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ height: "70vh" }}
        >
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0841D4"
            secondaryColor="#0841D4"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
          />
        </div>
      );
    } else if (
      !MainData ||
      !MainData.MainData ||
      MainData.MainData.length === 0
    ) {
      return (
        <div className="d-flex justify-content-center align-items-center flex-column h-75 my-5">
          <img src={NotFound} className="img-fluid w-25 h-25" alt="" />
          <h4>Not Any Attempt quiz</h4>
        </div>
      );
    } else {
      const user = MainData.MainData[0]?.userId || {};
      return (
        <div className="white-bg-employeee">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 col-12">
              {MainData.MainData.map((item, index) => (
                <div key={index} className="basic-episode mb-lg-4 mb-3">
                  <div className="basic-first">
                    <div className="basic-episode-img">
                      <img
                        src={item?.trainingId?.trainigthumbnail}
                        className="img-fluid"
                        alt="not any Thumbnail add"
                      />
                    </div>
                    <div className="date-detail">
                      <h4>{item?.trainingId?.title}</h4>
                      <p>{convertToSimpleDate(item?.createdAt)}</p>
                    </div>
                  </div>
                  <div className="quiz-mark">
                    <h5>{item?.score}</h5>
                    <h6>Quiz Point</h6>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-xl-4 col-lg-6 col-md-10 col-12">
              <div className="bg-for-tran">
                <div className="d-flex-drop mb-25">
                  <div className="user-name-img">
                    <img
                      src={user.image || NotFound}
                      onError={(e) => (e.currentTarget.src = NotFound)}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="user-name-id-training">
                      <h5>{user.name}</h5>
                      <p>
                        ID-<span>{user.mainId}</span>
                      </p>
                    </div>
                  </div>
                  <div className="quiz-point">
                    <h3>
                      {MainData?.RightAnswer}/{MainData?.TotalQuestion}
                    </h3>
                    <h4>Quiz Point</h4>
                  </div>
                </div>

                <PieChart
                  chartDetail={[
                    MainData?.NoOfAttempt,
                    MainData?.TotalTraining - MainData?.NoOfAttempt,
                  ]}
                  ShowDetail={`${MainData?.NoOfAttempt}/${MainData?.TotalTraining}`}
                />
                <div className="export-btn mt-3">
                  <Link
                    onClick={handleSendNotification}
                    className="export-btn btn w-100"
                  >
                    Send Warning
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="asside">
      <div className="container-fluid">
        <Header />
        <Sidebar />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/EmployeeUpdate"} type="submit">
                <i className="fa-solid fa-arrow-left"></i>
              </Link>
              <h4>Employee History</h4>
            </div>
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
}

export default EmployeeUpdateMain;
