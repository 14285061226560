import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";

const useComapany = () => {
  const [cookie] = useCookies(["companyName"]);
  const [isLoad, setisLoad] = useState(false);
  const [Company, setCompany] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(`${Api.Company}?name=${cookie.companyName}`);
        setCompany((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);

  return { isLoad, Company };
};

export default useComapany;
