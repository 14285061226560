import React from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { POSTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
const AddModal = ({ point, id, handleUpdate }) => {
  const [cookie] = useCookies(["user"]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleAddPoint = (data) => {
    toast
      .promise(POSTAPI(Api.PlanPoint, { ...data, ...point, id }, cookie.user), {
        pending: "Loading ....",
        success: "Point Add Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        handleUpdate();
      })
      .catch((er) => {
        console.log(er);
      });
    reset();
    document.getElementById("closeModal").click();
  };
  return (
    <div
      class="modal fade "
      id="addpoint"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered  ">
        <div class="modal-content spl-modal-body">
          <div className="close-modal-btn">
            <Link
              id="closeModal"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa-solid fa-xmark"></i>
            </Link>
          </div>
          <form class="modal-body" onSubmit={handleSubmit(handleAddPoint)}>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Add Title
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Point"
                {...register("title", { required: true })}
              />
              {errors?.title && (
                <p className=" p-2" style={{ color: "red" }}>
                  Please Enter Point !
                </p>
              )}
            </div>
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label mb-2">
                Add Description
              </label>
              <textarea
                rows="4"
                className="form-control"
                {...register("detail", { required: true })}
              ></textarea>
              {errors?.detail && (
                <p className=" p-2" style={{ color: "red" }}>
                  Please Enter Description !
                </p>
              )}
            </div>
            <div class="mb-3">
              <button type="submit" className="emebtnss">
                <i class="fa-solid fa-plus me-2"></i>Add Point
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
