import React from 'react'
import { Link } from "react-router-dom";
import Header from './Header'

function QuizThree() {
  return (
    <div>
        <Header/>
        
        <div className='asside-two'>
            <div className='container'>
                    <div className='question-no'>
                        <h3>Q-1</h3>
                        <h4>Lorem Ipsum is simply  text of the printing and typesetti ng industry ?</h4>
                    </div>
                    <div class="form-check frm-check  mb-3 bg-blue-check">
                        <input class="form-check-input frn-check-input " type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                        <label class="form-check-label frn-check-input " for="flexRadioDefault1">
                        A. option a 
                        </label>
                    </div>
                    <div class="form-check frm-check mb-3 bg-blue-check">
                        <input class="form-check-input frn-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                        <label class="form-check-label frn-check-input" for="flexRadioDefault1">
                        B. option a 
                        </label>
                    </div>
                    <div class="form-check frm-check mb-3 bg-blue-check">
                        <input class="form-check-input frn-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                        <label class="form-check-label frn-check-input" for="flexRadioDefault1">
                        C. option a 
                        </label>
                    </div>
                    <div class="form-check frm-check bg-green mb-3 bg-green-check">
                        <input class="form-check-input frn-check-input bg-green" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked/>
                        <label class="form-check-label frn-check-input" for="flexRadioDefault1">
                        D. option a 
                        </label>
                    </div>
                    <div className='quizsub-btn'>
                        <Link to={'/QuizResult'} type='submit' >Next question<i class="fa-solid fa-chevron-right"></i></Link>
                    </div>
            </div>
        
        </div>
        </div>
        
  )
}

export default QuizThree