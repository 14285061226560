import React from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";

const ChangePassword = () => {
  const [cookie] = useCookies(["user"]);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const handleChangePassword = (data) => {
    if (data.newPassword !== data.conPassword)
      return toast.error("Password and Confirm Password Must Be Match !");
    toast
      .promise(PUTAPI(Api.ChangePassword, data, cookie.user), {
        pending: "Wait Few Moment !",
        success: "Password Change Successfully !",
        error: "Password Not Match !",
      })
      .then((val) => {
        navigate("/Home");
      })
      .catch(() => {});
    reset();
  };
  return (
    <div className="bg-login">
      <div className="container">
        <div className="row vh-100 d-flex align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8 col-12 my-3">
            <div className="signin-page">
              <div className="top-login-text">
                <h2>Change Pasword</h2>
                <p>
                  Please Change Pasword to your account and start the adventure
                </p>
              </div>
              <form onSubmit={handleSubmit(handleChangePassword)}>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("oldPassword", { required: true })}
                  />
                  <label for="floatingInput">Current Password</label>
                  {errors?.oldPassword && (
                    <p className="p-2" style={{ color: "red" }}>
                      Please Enter Current Password !
                    </p>
                  )}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    {...register("newPassword", { required: true })}
                  />
                  <label for="floatingInput">New Password</label>
                  {errors?.newPassword && (
                    <p className="p-2" style={{ color: "red" }}>
                      Please Enter New Password !
                    </p>
                  )}
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="password"
                    className="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    {...register("conPassword", { required: true })}
                  />
                  <label for="floatingPassword">Confirm New Password</label>
                  {errors?.conPassword && (
                    <p className="p-2" style={{ color: "red" }}>
                      Please Enter Confirm Password !
                    </p>
                  )}
                </div>
                <button type="submit" className="btn btn-login">
                  Confirm
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
