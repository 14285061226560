import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import java from "../Image/doc-post.svg";
import NotFound from "../Image/notfound.svg";
import Sidebar from "./Sidebar";
import { useCookies } from "react-cookie";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";
import { CSVLink } from "react-csv";
import ExportEmployee from "../common/ExportEmployee";

function EmployeeUpdate() {
  const [AllEmployee, setAllEmployee] = useState([]);
  const [Employee, setEmployee] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [cookie] = useCookies(["user", "companyName"]);
  const [Query, setQuery] = useState("");

  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleSearch = (e) => {
    const { value } = e.target;
    const newData = AllEmployee?.filter(
      (el) =>
        el?.name?.toLowerCase().toString()?.includes(value.toLowerCase()) ||
        el?.designation
          ?.toLowerCase()
          .toString()
          ?.includes(value.toLowerCase()) ||
        el?.score?.toString()?.includes(value)
    );
    setEmployee((prev) => newData);
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        let query = Query ? `order=${Query}` : "";
        const data = await GETAPI(
          `${Api.Employee}?type=${cookie.companyName}&${query}`,
          cookie.user
        );
        setAllEmployee((prev) => data.data);
        setEmployee((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);

  return (
    <div>
      <div className="asside">
        <div className="container-fluid">
          <Header />
          <Sidebar />
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-6 col-12">
              <div className="back-post-section mb-0">
                <Link to={"/Home"} type="submit">
                  <i class="fa-solid fa-arrow-left"></i>
                </Link>
                <h4>Employee update </h4>
              </div>
            </div>
          </div>
          <div className="con-back-bg">
            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="pro-add-new px-0">
                  <p className="text-black">Employee List</p>
                  <div class="form-group has-search header-bottom-search post-page-search-bg">
                    <span class="fa fa-search form-control-feedback"></span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Number "
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <ExportEmployee />
              <div className="col-xl-6 col-12">
                <div className="pro-add-new px-0">
                  <div className="quiz-point select-color">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setQuery((prev) => e.target.value);
                        handleUpdate();
                      }}
                    >
                      <option value={""} selected>
                        Order By Score
                      </option>
                      <option value="desc">Highest Score </option>
                      <option value="asc">Low Score </option>
                    </select>
                  </div>
                  <div className="export-btn">
                    <Link
                      id="ExportModal"
                      data-bs-toggle="modal"
                      data-bs-target="#ExportModal"
                      className="export-btn btn"
                    >
                      Export
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {!isLoad ? (
              <div className="d-flex justify-content-center  align-items-center w-100  h-50">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#0841D4"
                  secondaryColor="#0841D4"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                />
              </div>
            ) : !Employee?.length ? (
              <div className="d-flex justify-content-center  align-items-center  flex-column ">
                <img src={NotFound} className="w-25 h-25 img-fluid " alt="" />
                <h4>Employee Not Found !</h4>
              </div>
            ) : (
              Employee?.map((item) => (
                <div className="community-user mt-0">
                  <div className="user-commen">
                    <div className="d-flex align-items-center mmm-6">
                      <div className="user-coomen-img">
                        <img
                          src={item?.image}
                          onError={(e) => (e.currentTarget.src = java)}
                          className="imgfluid"
                          alt=""
                        ></img>
                      </div>
                      <div className="user-commen-text">
                        <h3>{item?.name}</h3>
                        <h4>
                          {item?.designation == "undefined"
                            ? "-"
                            : item?.designation}
                        </h4>
                      </div>
                    </div>
                    <div className="quiz-mark">
                      <h5>
                        {item?.score}/{item?.TotalQuestion}
                      </h5>
                      <h6>Quiz Point</h6>
                    </div>
                  </div>
                  <div className="view-s-btn">
                    <Link to={`/EmployeeUpdateMain/${item?._id}`}>View</Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeUpdate;
