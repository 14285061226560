export const Main = "https://safe4you-api.4born.in/v1";
// export const Main = "http://localhost:8000/v1";
const Api = {
  LoginApi: `${Main}/user/login`,
  User: `${Main}/user/user`,
  UserCerificate: `${Main}/user/cerificate`,
  Visitor: `${Main}/user/visitor`,
  Post: `${Main}/post/post`,
  ChangePassword: `${Main}/user/change-password`,
  Like: `${Main}/post/like`,
  Training: `${Main}/training/training`,
  Watch: `${Main}/training/watch`,
  Quiz: `${Main}/training/quiz`,
  TrainingLike: `${Main}/training/like`,
  Company: `${Main}/company/company`,
  Community: `${Main}/user/community`,
  Employee: `${Main}/training/employee`,
  Chart: `${Main}/chart/chart`,
  WorkSpace: `${Main}/workspace/workspace`,
  ImportWorkSpace: `${Main}/workspace/import-data`,
  LastTraing: `${Main}/training/last-training`,
  AddCertificate: `${Main}/training/add-certificate`,
  Export: `${Main}/training/export`,
  Emergency: `${Main}/emergency/emergency`,
  PlanLayout: `${Main}/plan-layout/plan`,
  PlanPoint: `${Main}/plan-layout/Point`,
  Notification: `${Main}/notification/notification`,
  Visitorshow: `${Main}/user/showvisitor`,
  ChartEmpShow: `${Main}/trainingpichart/quizpichart`,
};

export default Api;
