import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { POSTAPI } from "../utils/Integration";
const ContactUs = () => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSendContact = (data) => {
    const newForm = new FormData();
    newForm.append("FirstName", data.FirstName);
    newForm.append("LastName", data.LastName);
    newForm.append("EMPCode", data.EMPCode);
    newForm.append("phoneNu", data.phoneNu);
    newForm.append("message", data.message);

    toast
      .promise(POSTAPI("https://safe4you.4born.in/contact.php", newForm), {
        pending: "Wait Few Moment !",
        success: "Thank You For Contact Us !",
        error: "Something Went Wrong !",
      })
      .then(() => {})
      .catch((er) => {
        console.log(er);
      });
    reset();
  };
  return (
    <div className="asside">
      <div className="container-fluid">
        {<Sidebar />}
        {<Header />}
        <div className="row align-items-center">
          <div className="col-lg-6 col-12">
            <div className="let-contact">
              <h5>Let's talk with us</h5>
              <p>
                Questions, comments, or suggestions? Simply fill in the form and
                we’ll be in touch shortly.
              </p>
              <div className="con-loc-icon mt-0">
                <i class="fa-solid fa-location-dot"></i>
                <h3>
                  1055 Arthur ave Elk Groot, 67.New Palmas South Carolina.
                </h3>
              </div>
              <div className="con-loc-icon">
                <i class="fa-solid fa-phone-volume"></i>
                <Link to={"tel:+1 234 678 9108 99"}>+1 234 678 9108 99</Link>
              </div>
              <div className="con-loc-icon">
                <i class="fa-regular fa-envelope"></i>
                <Link to={"mailto:Contact@moralizer.com"}>
                  Contact@moralizer.com
                </Link>
              </div>
              <Link to={"#"} className="let-contact-to">
                Chat Direct With Us
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <form
              onSubmit={handleSubmit(handleSendContact)}
              class="row contact-frm"
            >
              <div class="col-md-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  placeholder="First Name"
                  {...register("FirstName", { required: true })}
                />
                {errors?.FirstName && (
                  <p className="p-1 " style={{ color: "red" }}>
                    Please Enter FirstName !
                  </p>
                )}
              </div>
              <div class="col-md-6 mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="inputPassword4"
                  placeholder="Last Name"
                  {...register("LastName", { required: true })}
                />
                {errors?.LastName && (
                  <p className="p-1 " style={{ color: "red" }}>
                    Please Enter Last Name !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  placeholder="Employee Code"
                  {...register("EMPCode", { required: true })}
                />
                {errors?.EMPCode && (
                  <p className="p-1 " style={{ color: "red" }}>
                    Please Enter EMPCode !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3">
                <input
                  type="number"
                  class="form-control"
                  id="inputAddress"
                  placeholder="Phone Number"
                  {...register("phoneNu", { required: true })}
                />
                {errors?.phoneNu && (
                  <p className="p-1 " style={{ color: "red" }}>
                    Please Enter Phone Number !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder=" Your Message..."
                  {...register("message", { required: true })}
                ></textarea>
                {errors?.phoneNu && (
                  <p className="p-1 " style={{ color: "red" }}>
                    Please Enter Message !
                  </p>
                )}
              </div>
              <div class="col-12 ">
                <button type="submit" class="btn btn-primary  w-100 ">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
