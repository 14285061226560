import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import NotFound from "../Image/notfound.svg";
import AddModal from "../common/emergency/AddModal";
import EditModal from "../common/emergency/EditModal";
import { useState } from "react";
import { DELETEAPI, GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const EmergencyContact = () => {
  const [MainData, setMainData] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [SignleData, setSignleData] = useState(null);
  const [cookie] = useCookies(["user", "companyName", "role"]);
  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleDelete = (id) => {
    toast
      .promise(DELETEAPI(`${Api.Emergency}/${id}`, cookie.user), {
        pending: "Wait Few Moment !",
        success: "Delete Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        handleUpdate();
      })
      .catch(() => {});
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(
          `${Api.Emergency}/${cookie.companyName}`,
          cookie.user
        );
        setMainData((prev) => data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div className="asside">
      <div className="container-fluid">
        {<Sidebar />}
        {<Header />}
        <div className="row">
          <div className="col-12">
            <div className="postabout-sec-bg">
              <div className="px-0 e-content">
                <p>Emergency Contact</p>
                {cookie.role != "employee" && (
                  <div className="modal-add-emergency">
                    <Link
                      to={"#"}
                      data-bs-toggle="modal"
                      data-bs-target="#demoone"
                    >
                      <i class="fa-solid fa-plus"></i>Add Emergency Contact
                    </Link>
                  </div>
                )}
              </div>
              {!isLoad ? (
                <div className="d-flex justify-content-center  align-items-center h-75 my-5">
                  <MutatingDots
                    visible={true}
                    height="100"
                    width="100"
                    color="#0841D4"
                    secondaryColor="#0841D4"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                  />
                </div>
              ) : !MainData?.length ? (
                <div className="d-flex justify-content-center  align-items-center  flex-column  my-5">
                  <img src={NotFound} className="img-fluid  w-25 h-25" alt="" />
                  <h4>Emergency Not Found !</h4>
                </div>
              ) : (
                MainData?.map((item, index) => (
                  <div className="e-con-number">
                    <div className="e-number-one">
                      <p>{index + 1}</p>
                    </div>
                    <div className="e-icon-name-nember">
                      <h4>
                        <i class="fa-solid fa-phone-volume"></i> {item?.service}
                        : <Link to={"tel:+919876543210"}>{item?.contact}</Link>
                      </h4>
                    </div>
                    {cookie?.role != "employee" && (
                      <div className="edit-delete-btn ">
                        <div className="view-s-btn my-0 me-0">
                          <Link
                            onClick={() => setSignleData((prev) => item)}
                            data-bs-toggle="modal"
                            data-bs-target="#demotwo"
                          >
                            Edit
                          </Link>
                        </div>
                        <div className="remove-btn ms-2">
                          <Link onClick={() => handleDelete(item?._id)}>
                            <i class="fa-solid fa-trash"></i>Delete
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <AddModal update={handleUpdate} />
      <EditModal item={SignleData} update={handleUpdate} />
    </div>
  );
};

export default EmergencyContact;
