import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "./Header";

import uploadpdf from "../Image/upload-img.svg";
import uploadpdfc from "../Image/pdf-formate-upload.svg";
import { MdCloudDone } from "react-icons/md";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";

const UploadImagePdf = () => {
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const [document, setdocument] = useState(null);
  const [cookie] = useCookies(["user"]);
  const navigate = useNavigate();
  const handleUpdate = () => {
    if (!(image || document))
      return toast.error("Atleast One Filed Required !");
    const newForm = new FormData();
    newForm.append("id", id);
    if (image) newForm.append("image", image);
    if (document) newForm.append("document", document);

    toast
      .promise(PUTAPI(Api.PlanLayout, newForm, cookie.user), {
        pending: "Loading...",
        success: "PlanLayout Update Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {})
      .catch((er) => {});
    navigate("/PlantLayout");
  };
  return (
    <div>
      <div className="asside-two">
        <div className="container-fluid">
          <Header />
          <div className="back-post-section">
            <Link to={"/PlantLayout"} type="submit">
              {" "}
              <i class="fa-solid fa-arrow-left"></i>{" "}
            </Link>
            <h4>Upload Images & PDF Here</h4>
          </div>
          <div className="row justify-content-between ">
            <div className="col-lg-12 col-md-12">
              <div className="commen-layout-upload">
                <div className="profile-upload-img-one mb-4">
                  <input
                    type="file"
                    id="profile"
                    hidden
                    onChange={(e) => {
                      setImage((prev) => e.target.files[0]);
                    }}
                  />
                  {image ? (
                    <label htmlFor="profile">
                      <MdCloudDone size={66} color="#23aa2d" />
                    </label>
                  ) : (
                    <div>
                      <label htmlFor="profile">
                        <img src={uploadpdf} alt=""></img>
                      </label>
                    </div>
                  )}
                  <h4>Upload Post Here</h4>
                </div>
                <div className="profile-upload-img-two">
                  <div className="pdf-pic">
                    <img src={uploadpdfc} alt=""></img>
                  </div>
                  <label
                    htmlFor="pdf"
                    className="uplaod-pdf p-0 bg-transparent"
                  >
                    <input
                      type="file"
                      name=""
                      id="pdf"
                      hidden
                      onChange={(e) => setdocument((prev) => e.target.files[0])}
                    />
                    <div
                      className={`btn btn-upload-pdf ${
                        document && "text-bg-success "
                      }`}
                    >
                      Upload PDF
                    </div>
                  </label>
                </div>
                <div class="col-12 mt-3">
                  <button
                    onClick={handleUpdate}
                    type="submit"
                    class=" update-plan-btn"
                  >
                    Update Plan assembly Point
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImagePdf;
