import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import evpost from "../Image/evpost.svg";
import training from "../Image/training.svg";
import plantlayout from "../Image/plant-layout.svg";
import organizationchart from "../Image/organization-chart.svg";
import emergancycontact from "../Image/emergancy-contact.svg";
import keyicon from "../Image/key-icon.svg";
import kaizenicon from "../Image/kaizen-icon.svg";
import basic from "../Image/post-one.png";
import posttwo from "../Image/post-two.svg";
import PiaChart from "./PiaChart";
import graphicicon from "../Image/graphic-icon.svg";
import emi from "../Image/emergency-modal-icon.svg";
import TrainingChart from "../common/TrainingChart";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";
import PostList from "../common/PostList";
import { MutatingDots } from "react-loader-spinner";

const Home = () => {
  const [isLoad, setisLoad] = useState(false);
  const [Post, setPost] = useState([]);
  const [Contact, setContact] = useState([]);
  const [cookie] = useCookies(["user", "companyName"]);
  const [video, setvideo] = useState(null);
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    animateOut: "slideoutup",
    nav: true,
    dots: false,
    margin: 0,
    responsive: {
      1350: {
        items: 5,
      },
      1000: {
        items: 4,
      },
      810: {
        items: 3,
      },
      500: {
        items: 2,
      },
      370: {
        items: 1,
        innerwidth: "100%",
        outerwidth: "100%",
      },
    },
  };

  useEffect(() => {
    function handleOpen() {
      if (!localStorage.getItem("login")) {
        document.getElementById("emergance").click();
        localStorage.setItem("login", true);
      }
    }
    let time = setTimeout(handleOpen, 3000);
    return () => {
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(Api.Post, cookie.user);
        const ContactData = await GETAPI(
          `${Api.Emergency}/${cookie.companyName}`,
          cookie.user
        );
        setContact((prev) => ContactData.data);

        const lastData = await GETAPI(
          `${Api.LastTraing}?type=Safe4you`,
          // `${Api.LastTraing}?type=${cookie.companyName}`,
          cookie.user
        );
        setvideo((prev) => lastData.data);

        setPost((prev) => data.data?.slice(0, 3));
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, []);
  return (
    <div>
      {/* Emergency Contact  */}
      <Link
        to={"#"}
        type="submit"
        id="emergance"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        hidden
      >
        start training now <i class="fa-solid fa-arrow-right"></i>
      </Link>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body modal-emergency">
              <div className="close-modal-btn">
                <Link type="button" data-bs-dismiss="modal" aria-label="Close">
                  <i class="fa-solid fa-xmark"></i>
                </Link>
              </div>
              <div className="eme-logo-text">
                <img src={emi} className="img-fluid" alt="S" />
                <h3>Emergency Contact</h3>
              </div>
              {Contact?.map((item) => (
                <div className="fire-safety-call">
                  <p>{item?.service} </p>
                  <h5>
                    <Link to={`tel:${item?.contact}`}>{item?.contact}</Link>
                  </h5>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {<Sidebar />}
      {<Header />}
      <div className="asside home-owl">
        <div className="container-fluid">
          <div className="row">
            {/* <OwlCarousel className="owl-theme home-slider-first" {...options}>
              <Link to={"/PostAbout"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={evpost} className="img-fluid" alt="s" />
                    <h3>ESH Post</h3>
                  </div>
                </div>
              </Link>
              <Link to={"/Training"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={training} className="img-fluid" alt="s" />
                    <h3>Training</h3>
                  </div>
                </div>
              </Link>
              <Link to={"/PlantLayout"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={plantlayout} className="img-fluid" alt="s" />
                    <h3>
                      Plant
                      <br /> Layout
                    </h3>
                  </div>
                </div>
              </Link>
              <Link to={"/OrganizationChart"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img
                      src={organizationchart}
                      className="img-fluid"
                      alt="s"
                    />
                    <h3>
                      Organization
                      <br /> Chart
                    </h3>
                  </div>
                </div>
              </Link>
              <Link to={"/EmergencyContact"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={emergancycontact} className="img-fluid" alt="s" />
                    <h3>
                      On site
                      <br /> emergency
                      <br /> contact
                    </h3>
                  </div>
                </div>
              </Link>
              <Link to={"/NearMissMain"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={keyicon} className="img-fluid" alt="s" />
                    <h3>Near Miss</h3>
                  </div>
                </div>
              </Link>
              <Link to={"/KaizenMain"}>
                <div class="item evpost">
                  <div className="evpost-in">
                    <img src={kaizenicon} className="img-fluid" alt="s" />
                    <h3>Kaizen</h3>
                  </div>
                </div>
              </Link>
            </OwlCarousel> */}
          </div>
        </div>

        <div className="container-fluid">
          {!isLoad ? (
            <div className="d-flex justify-content-center  align-items-center my-4">
              <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#0841D4"
                secondaryColor="#0841D4"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
              />
            </div>
          ) : (
            <div className="row">
              <div className="col-xl-8 col-lg-12 col-md-12 col-12">
                <div className="basic-bg">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="img-card">
                        <div className="postion-btn">
                          <div className="in-img ">
                            <video>
                              <source src={video?.trainingId.video} />
                            </video>
                            {/* <img src={basic} className="img-fluid" alt="s" /> */}
                            <div className="btn-click-to-play">
                              <Link
                                to={`/TrainingAbove/${video?.trainingId._id}`}
                              >
                                {" "}
                                <i class="fa-solid fa-play"></i>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                        <h3>{video?.trainingId.title}</h3>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-12">
                      <div className="video-txt">
                        <h2>{video?.trainingId.title}</h2>
                        <p className="text-cut">{video?.trainingId.about}</p>
                        <Link to={"/Training"} type="submit">
                          start training now{" "}
                          <i class="fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-section">
                  <h2>Post Section</h2>
                  <Link to={"/Post"}>
                    See all<i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
                <div className="row">
                  {!isLoad ? (
                    <div></div>
                  ) : !Post?.length ? (
                    <div className="col-12">
                      <div className="d-flex justify-content-center align-items-center flex-column ">
                        <h5>Post Not Found !</h5>
                      </div>
                    </div>
                  ) : (
                    Post.map((item) => <PostList item={item} col="col-lg-4" />)
                  )}
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-10 col-12">
                <TrainingChart />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;

{
  /* <div className='col-lg-4 col-md-6 col-12'>
<div className='char-design'>
  <div className='d-flex-drop align-items-center'>
    <div className='drop-txt' >
      <h2>Training </h2>
      <div className='quiz-point select-color mt-2'>
                <select class="form-select" aria-label="Default select example">
                  <option selected>Safe 4 You Training</option>
                  <option value="1">Safe 4 You Training</option>
                  <option value="2">Safe 4 You Training</option>
                  <option value="3">Safe 4 You Training</option>
                </select>
              </div>
    </div>
    <div className='quiz-point'>
      <h3>25/30</h3>
              <h4>Quiz Point</h4>
      <div className='quiz-history'>
        <Link to="#"><i class="fa-regular fa-floppy-disk"></i>Quiz History</Link>
      </div>
    </div>
  </div>
  <PiaChart />
</div>
</div> */
}
