import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Link } from "react-router-dom";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";
import { Cookies, useCookies } from "react-cookie";

const Visitror = () => {
  const [isLoad, setisLoad] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    (async () => {
      try {
        const data = await GETAPI(Api.Visitorshow, Cookies.user);
        setTableData(data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = TableData.filter(
    (item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    (item) => item.companyName.toLowerCase().includes(searchTerm.toLowerCase()),
    (item) => item.companySite.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Sidebar />
      <Header />
      <div className="asside">
        <div className="back-post-section mb-3">
          <Link to={"/Home"} type="submit">
            <i className="fa-solid fa-arrow-left"></i>
          </Link>
          <h4>Visitor </h4>
        </div>

        {!isLoad ? (
          <div className="d-flex justify-content-center  align-items-center ">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#0841D4"
              secondaryColor="#0841D4"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
            />
          </div>
        ) : (
          <main className="cd__main " style={{ width: "90%", margin: "auto" }}>
            <div className="search-box mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
                className="form-control "
              />
            </div>

            <table className="table  bg-white">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Visitor Name</th>
                  <th>Contact Number</th>
                  <th>Company Name</th>
                  <th>Company WebSite</th>
                  <th>Visiting Purpose</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1 + indexOfFirstItem}</td>
                    <td>{item.name}</td>
                    <td>{item.contact}</td>
                    <td>{item.companyName ? item.companyName : "-"}</td>
                    <td>{item.companySite ? item.companySite : "-"}</td>
                    <td>{item.purpose}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {[...Array(totalPages).keys()].map((number) => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`page-item ${
                    currentPage === number + 1 ? "active" : ""
                  }`}
                >
                  {number + 1}
                </button>
              ))}
            </div>
          </main>
        )}
      </div>
    </>
  );
};

export default Visitror;
