import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import { MdOutlineCloudDone } from "react-icons/md";
import uploadpdf from "../Image/pdf-upload-icon.svg";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { useCookies } from "react-cookie";

const UploadPost = () => {
  const [image, setImage] = useState(null);
  const [cookie] = useCookies(["user"]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const handleUploadPost = (data) => {
    if (!image) return toast.error("Content must be required !");
    let newForm = new FormData();
    newForm.append("title", data?.title);
    newForm.append("detail", data?.detail);
    newForm.append("content", image);
    toast
      .promise(POSTAPI(Api.Post, newForm, cookie.user), {
        pending: "Wait Few Moment !",
        success: "Post Upload Successfully !",
        error: "Somthing Wrong !",
      })
      .then(() => {
        navigate("/Post");
      })
      .catch(() => {});
    reset();
    setImage((prev) => null);
  };
  return (
    <div>
      <div className="asside-two">
        <div className="container-fluid">
          <Header />
          <div className="back-post-section">
            <Link to={"/Post"} type="submit">
              {" "}
              <i class="fa-solid fa-arrow-left"></i>{" "}
            </Link>
            <h4>Upload Post</h4>
          </div>
          <form
            className="row justify-content-center "
            onSubmit={handleSubmit(handleUploadPost)}
          >
            <div className="col-lg-7 col-md-12">
              <div className="profile-upload-img-one">
                <input
                  type="file"
                  id="profile"
                  hidden
                  onChange={(e) => {
                    if (e.target.files.length) {
                      setImage((prev) => e.target.files[0]);
                    }
                  }}
                />
                {image ? (
                  <label htmlFor="profile">
                    <div>
                      <MdOutlineCloudDone size={95} color="#34A853" />
                    </div>
                  </label>
                ) : (
                  <div>
                    <label htmlFor="profile">
                      <img src={uploadpdf} alt=""></img>
                    </label>
                  </div>
                )}
                <h4>Upload Post Here</h4>
              </div>
              <div class="col-12 mb-3 ">
                <label
                  htmlFor="exampleFormControlInput1"
                  class="form-label frm-label"
                >
                  Post Title
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  placeholder="First Floor, Nilkanth Complex, Virani Circle, above "
                  {...register("title", { required: true })}
                />
                {errors?.title && (
                  <p className="p-2 " style={{ color: "red" }}>
                    Please Enter Post Title !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  class="form-label frm-label mt-0"
                >
                  About Post
                </label>
                <textarea
                  class="form-control "
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder="First Floor, Nilkanth Complex, Virani Circle, above "
                  {...register("detail", { required: true })}
                ></textarea>
                {errors?.detail && (
                  <p className="p-2 " style={{ color: "red" }}>
                    Please Enter Post About !
                  </p>
                )}
              </div>
              <div class="col-12 mb-3 w-100 ">
                <button type="submit" class=" update-plan-btn w-100">
                  Upload Plan
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadPost;
