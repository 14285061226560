import React, { useState } from "react";
import { Link } from "react-router-dom";
import basic from "../Image/post-one.png";
import posttwo from "../Image/post-two.svg";
import postthree from "../Image/PostThree.svg";
import logo from "../Image/logo.svg";
import psss from "../Image/ppt-post.svg";
import { useCookies } from "react-cookie";
import { DELETEAPI, POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";
const PostList = ({ item, col = "col-lg-3" }) => {
  const [cookies] = useCookies(["user"]);
  const [isLike, setisLike] = useState(item?.isLike);
  const [Likes, setLikes] = useState(Number(item?.likes));
  const handleLikes = async () => {
    try {
      if (isLike) {
        setLikes((prev) => prev - 1);
        setisLike(false);
        await DELETEAPI(`${Api.Like}/${item?._id}`, cookies.user);
      } else {
        setLikes((prev) => prev + 1);
        setisLike(true);
        await POSTAPI(Api.Like, { id: item?._id }, cookies.user);
      }
    } catch (er) {
      console.log(er);
    }
  };
  return (
    <div className={`${col} col-md-6 col-6 mb-3`}>
      <div className="post-card">
        <div className="img-card">
          <div className="postion-btn">
            <div className="in-img post-section-img hover-video-btn">
              {item?.type == "image" && (
                <img
                  src={item?.content}
                  onError={(e) => (e.currentTarget.src = basic)}
                  className="img-fluid"
                  alt=""
                />
              )}
              {item?.type == "video" && (
                <video className="img-fluid">
                  <source src={item?.content} />
                </video>
              )}
              {item?.type == "pdf" && (
                <img src={posttwo} className="img-fluid" alt="" />
              )}
              {(item?.type == "pptx" || item?.type == "ppt") && (
                <img src={psss} className="" alt="" />
              )}
              {item?.type == "doc" && (
                <img src={postthree} className="img-fluid" alt="" />
              )}
              <div
                className={`heart-rating  ${isLike && "active-like"}`}
                onClick={handleLikes}
              >
                <h3>
                  <i class="fa-solid fa-heart"></i>
                  {Likes}
                </h3>
              </div>
              <Link to={`/PostAbout/${item?._id}`} className="play-btn-hover">
                {item?.type == "video" ? (
                  <i class="fa-solid fa-play"></i>
                ) : (
                  <i class="fa-solid fa-eye"></i>
                )}
              </Link>
            </div>
          </div>
          <div className="insure-txt">
            <h5 className="text-cut">{item?.title} </h5>
            <hr />
            <div className="graphic-icon">
              <img
                src={item?.author?.image}
                className="img-fluid  h-auto  border-light rounded-5"
                style={{ width: "9%" }}
                onError={(e) => (e.currentTarget.src = logo)}
                alt="logo"
              />
              <h6>{item?.author?.name}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostList;
