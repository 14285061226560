import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import java from "../Image/doc-post.svg";
import uploadpdfc from "../Image/pdf-formate-upload.svg";
import NotFound from "../Image/notfound.svg";
import AddModal from "../common/community/AddModal";
import { DELETEAPI, GETAPI, POSTAPI } from "../utils/Integration";
import { useCookies } from "react-cookie";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

const Community = () => {
  const [AllUsers, setAllUsers] = useState([]);
  const [Users, setUsers] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const [cookie] = useCookies(["user"]);

  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleImportData = (e) => {
    let file = e.target.files[0];
    Papa.parse(file, {
      complete: (result) => {
        let newData = result.data.map((item) => {
          delete item.__v;
          delete item.updatedAt;
          delete item.createdAt;
          return item;
        });
        let FilterData = newData
          .filter((item) => !AllUsers?.some((el) => el._id == item?._id))
          .map((item) => {
            delete item?._id;
            delete item?.refId;
            return { ...item, image: item?.image?.split("/")?.splice(-1)[0] };
          });
        if (FilterData?.length) {
          toast
            .promise(
              POSTAPI(Api.Community, { Community: FilterData }, cookie.user),
              {
                pending: "Loading...",
                success: "Community Add Successfully !",
                error: "Something Went Wrong !",
              }
            )
            .then(() => {
              handleUpdate();
            })
            .catch((er) => {
              console.log(er);
            });
        }
      },
      header: true, // if the CSV file has headers
    });
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const newData = AllUsers?.filter(
      (item) =>
        item.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
        item.mainId?.toLowerCase()?.includes(value?.toLowerCase())
    );
    setUsers((prev) => newData);
  };

  const handleRemove = (id) => {
    toast
      .promise(DELETEAPI(`${Api.Community}/${id}`, cookie.user), {
        pending: "Wait Few Moment !",
        success: "User Delete Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        handleUpdate();
      })
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(Api.Community, cookie.user);
        setAllUsers((prev) => data.data);
        setUsers((prev) => data.data);
      } catch (er) {
      } finally {
        setisLoad(true);
      }
    })();
  }, [isUpdate]);
  return (
    <div>
      <div className="asside-two">
        <div className="container-fluid">
          <Header />
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-8 col-8">
              <div className="justify-content-between d-flex">
                <div className="back-post-section mb-0">
                  <Link to={"/Home"} type="submit">
                    {" "}
                    <i class="fa-solid fa-arrow-left"></i>{" "}
                  </Link>
                  <h4>Community </h4>
                </div>
                <div className="three-btns-em">
                  <Link
                    to={"#"}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalsa"
                  >
                    {" "}
                    Add Employee
                  </Link>
                  <input
                    type="file"
                    name=""
                    id="ImportData"
                    hidden
                    onChange={handleImportData}
                  />
                  <label htmlFor="ImportData"> Import</label>
                  <CSVLink data={AllUsers} filename="Community.csv">
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
            <AddModal update={handleUpdate} />
            <div
              class="modal fade "
              id="exampleModalimport"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-xl">
                <div class="modal-content">
                  <div class="modal-header border-none">
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div className="profile-upload-img-two">
                      <div className="pdf-pic">
                        <img src={uploadpdfc} alt="" />
                      </div>
                      <label
                        htmlFor="pdf"
                        className="uplaod-pdf p-0 bg-transparent"
                      >
                        <input type="file" name="" id="pdf" hidden />
                        <div className="btn btn-upload-pdf">Upload PDF</div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-4">
              <div class="form-group has-search header-bottom-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="community-top">
            {!isLoad ? (
              <div className="d-flex justify-content-center  align-items-center  h-50">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#0841D4"
                  secondaryColor="#0841D4"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                />
              </div>
            ) : !Users?.length ? (
              <div className="d-flex justify-content-center   align-items-center flex-column ">
                <img src={NotFound} className="w-25 h-25" alt="" />
                <h4>Community Not Found !</h4>
              </div>
            ) : (
              Users.map((item) => (
                <div className="community-user">
                  <div className="user-commen">
                    <div className="user-coomen-img">
                      <img
                        src={item?.image}
                        onError={(e) => (e.currentTarget.src = java)}
                        className="imgfluid"
                        alt=""
                      ></img>
                    </div>
                    <div className="user-commen-text">
                      <h3>
                        {item?.name} <span>({item?.mainId})</span>
                      </h3>
                      <h4>{item?.designation}</h4>
                    </div>
                  </div>
                  <div className="remove-btn">
                    <Link onClick={() => handleRemove(item?._id)}>
                      <i class="fa-solid fa-trash"></i> Remove
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;

// <div className="community-user">
//               <div className="user-commen">
//                 <div className="user-coomen-img">
//                   <img src={java} className="imgfluid" alt=""></img>
//                 </div>
//                 <div className="user-commen-text">
//                   <h3>
//                     Admin 1 <span>(EMP45123)</span>
//                   </h3>
//                   <h4>Manager of IOS</h4>
//                 </div>
//               </div>
//               <div className="remove-btn">
//                 <Link to={"#"}>
//                   <i class="fa-solid fa-trash"></i> Remove
//                 </Link>
//               </div>
//             </div>
