import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import java from "../Image/user.png";
import hello from "../Image/user.png";
import { useState } from "react";
import useGetInfo from "../hook/useGetInfo";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";

function EditProfile() {
  const [cookies] = useCookies(["user"]);
  const { register, reset, handleSubmit } = useForm();
  const { isLoad, User } = useGetInfo();
  const [image, setImage] = useState(null);
  const [ImageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  const handleUpdateUser = (data) => {
    let form_data = new FormData();
    form_data.append("name", data?.name || User?.name);
    form_data.append("designation", data?.designation || User?.designation);
    form_data.append("department", data?.department || User?.department);
    form_data.append("location", data?.location || User?.location);
    if (ImageFile) form_data.append("image", ImageFile);
    toast
      .promise(PUTAPI(Api.User, form_data, cookies.user), {
        pending: "Wait Few Moment !",
        success: "Profile Update Successfully !",
        error: "Something Wrong !",
      })
      .then(() => {
        navigate("/Profile");
      })
      .catch(() => {});
  };

  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/Profile"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Company Profile</h4>
            </div>
          </div>
        </div>
      </div>
      {!isLoad ? (
        <div className="d-flex justify-content-center  align-items-center  my-5">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#0841D4"
            secondaryColor="#0841D4"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
          />
        </div>
      ) : (
        <div className="container-fluid ">
          <div className="profile-section-back-design ">
            <div className="row">
              <div className="col-lg-5 col-md-12 mb-3">
                <div className="comp-edit">
                  <div className="profile-show">
                    <img
                      src={User?.image}
                      onError={(e) => (e.currentTarget.src = hello)}
                      className="imf-fluid"
                      alt=""
                    ></img>
                    <h3>{User?.name}</h3>
                    <h4>{User?.role}</h4>
                  </div>
                  {/* <div className="document-view">
                  <h5>Documents</h5>
                  <div className="quiz-document-two">
                    <div className="ivon-docu">
                      <i class="fa-regular fa-file"></i>
                      <div className="text-document">
                        <h3>Quiz Certificate</h3>
                        <h4>1.23 MB</h4>
                      </div>
                    </div>
                    <div className="download-btn-certificate">
                      <Link to={"#"}>
                        {" "}
                        <i class="fa-regular fa-circle-down"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
                  <div className="prof-infor">
                    <h3>Information</h3>
                    <div className="main-fe">
                      <div className="name-and-text-one w-100">
                        <div className="row   ">
                          <h4 className="col-4 ">Name</h4>
                          <h5 className="col-8 ">{User?.name}</h5>
                        </div>
                        <div className="row">
                          <h4 className="col-4">Employee Code</h4>
                          <h5 className="col-8">{User?.mainId}</h5>
                        </div>
                        <div className="row">
                          <h4 className="col-4">Designation</h4>
                          <h5 className="col-8">
                            {User?.designation == "undefined"
                              ? "-"
                              : User?.designation}
                          </h5>
                        </div>
                        <div className="row">
                          <h4 className="col-4">department</h4>
                          <h5 className="col-8">
                            {User?.department == "undefined" ||
                            !User?.department
                              ? "-"
                              : User?.department}
                          </h5>
                        </div>
                        <div className="row">
                          <h4 className="col-4">Location</h4>
                          <h5 className="col-8 ">
                            {User?.location == "undefined"
                              ? "-"
                              : User?.location}
                          </h5>
                        </div>
                      </div>
                      <div className="name-and-text-one name-and-text-two "></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <form
                  className="comp-edit"
                  onSubmit={handleSubmit(handleUpdateUser)}
                >
                  <div className="setting-click">
                    <h5>Settings</h5>
                  </div>
                  <div className="my-account">
                    <h4>My Account</h4>
                    <div className="profile-upload-img">
                      <img
                        src={image || User?.image}
                        onError={(e) => (e.currentTarget.src = java)}
                        alt=""
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        id="profile"
                        hidden
                        onChange={(e) => {
                          setImageFile((prev) => e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      <label htmlFor="profile">
                        <i class="fa-solid fa-pen"></i>
                      </label>
                    </div>
                    <div className="row g-3 my-acc-padding">
                      <div class="col-md-6 col-12 mb-3">
                        <label for="formGroupExampleInput" class="form-label">
                          First Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Jansh"
                          defaultValue={User?.name}
                          aria-label="First name"
                          {...register("name")}
                        />
                      </div>
                      <div className="col-md-6 col-12 mb-3">
                        <label
                          htmlFor="formGroupExampleInput"
                          class="form-label"
                        >
                          Employee Code
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder={User?.mainId}
                          readOnly
                          aria-label="Last name"
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label
                          htmlFor="formGroupExampleInput"
                          class="form-label"
                        >
                          Destination{" "}
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Destination"
                          defaultValue={
                            User?.designation == "undefined"
                              ? ""
                              : User?.designation
                          }
                          aria-label="Last name"
                          {...register("designation")}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label
                          htmlFor="formGroupExampleInput"
                          class="form-label"
                        >
                          Department
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          defaultValue={
                            User?.department == "undefined" || !User?.department
                              ? ""
                              : User?.department
                          }
                          aria-label="Last name"
                          {...register("department")}
                        />
                      </div>
                      <div className="col-12 mb-3">
                        <label
                          htmlFor="formGroupExampleInput"
                          class="form-label"
                        >
                          Location{" "}
                        </label>
                        <textarea
                          rows="4"
                          type="text"
                          class="form-control"
                          placeholder="Location"
                          defaultValue={
                            User?.location == "undefined" ? "" : User?.location
                          }
                          aria-label="Last name"
                          {...register("location")}
                        />
                      </div>
                      <div className="col-12 w-100">
                        <button type="submit" className="w-100">
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditProfile;

// import React from 'react'
// import { Link } from "react-router-dom";
// import Header from './Header'
// import java from '../Image/doc-post.svg'
// import hello from '../Image/doc-post.svg'
// import { useState } from 'react';

// function EditProfile() {
//     const [image, setImage] = useState("")
//     return (
//         <div className='asside-two'>
//             <div className='container-fluid'>
//                 <Header />
//                 <div className='row'>
//                     <div className='col-lg-6 col-md-6 col-12'>
//                         <div className='back-post-section'>
//                             <Link to={'/Profile'} type='submit'> <i class="fa-solid fa-arrow-left"></i> </Link>
//                             <h4>Company Profile</h4>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="container profile-section-back-design ">
//                 <div className="row justify-content-center">
//                     <div className="col-lg-7 col-md-12">
//                         <div className="comp-edit">
//                             <div className="setting-click">
//                                 <h5>Settings</h5>
//                             </div>
//                             <div className="my-account">
//                                 <h4>My Account</h4>
//                                 <div className="profile-upload-img">
//                                     <img src={image} onError={e => e.currentTarget.src = java} alt="" className='img-fluid' />
//                                     <input type="file" id='profile' hidden onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))} />
//                                     <label htmlFor="profile"><i class="fa-solid fa-pen"></i></label>
//                                 </div>
//                                 <div className="row g-3 my-acc-padding">
//                                     <div className="profile-info mb-3">
//                                         <div className='about-cmp'>
//                                             <label for="formGroupExampleInput" class="form-label">About Company :</label>
//                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="6">Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the  Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the </textarea>
//                                         </div>
//                                     </div>
//                                     <div className="col-12">
//                                         <Link to={'#'}>UPDATE   </Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EditProfile
