import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import NotFound from "../Image/notfound.svg";
import { useCookies } from "react-cookie";
import { MutatingDots } from "react-loader-spinner";
import { GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import PostList from "../common/PostList";
const Post = () => {
  const [cookie] = useCookies(["user", "role"]);
  const [Posts, setPosts] = useState([]);
  const [AllPost, setAllPost] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const handleSearch = (e) => {
    let key = e.target.value;
    const MainData = AllPost?.filter(
      (el) =>
        el?.title?.toLowerCase()?.includes(key.toLowerCase()) ||
        el?.detail?.toLowerCase()?.includes(key.toLowerCase()) ||
        el?.author?.name?.toLowerCase()?.includes(key.toLowerCase())
    );
    setPosts((prev) => MainData);
  };
  useEffect(() => {
    (async () => {
      try {
        setisLoad(false);
        const data = await GETAPI(Api.Post, cookie.user);
        setAllPost((prev) => data.data);
        setPosts((prev) => data.data);
      } catch (er) {
        console.log(er);
      } finally {
        setisLoad(true);
      }
    })();
  }, []);

  return (
    <div>
      {<Sidebar />}
      {<Header />}
      <div className="asside home-owl">
        <div className="container-fluid ">
          <div className="pro-add-new px-0">
            <p>Post Section</p>
            <div class="form-group has-search header-bottom-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
          {!isLoad ? (
            <div
              className="d-flex justify-content-center align-items-center flex-column  "
              style={{ height: "70vh" }}
            >
              <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#0841D4"
                secondaryColor="#0841D4"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
              />
            </div>
          ) : (
            <div className="row">
              {cookie.role !== "employee" && (
                <div className="col-lg-3 col-md-6 col-6 mb-3">
                  <Link to={"/UploadPost"}>
                    <div className="upload-file-post">
                      <i class="fa-solid fa-plus"></i>
                      <h3>Upload New Post</h3>
                    </div>
                  </Link>
                </div>
              )}
              {!Posts?.length
                ? cookie?.role == "employee" && (
                    <div className="d-flex justify-content-center  align-items-center  h-100 flex-column ">
                      <img src={NotFound} alt="" className="w-25 h-25" />
                      <h3>Post Not Found !</h3>
                    </div>
                  )
                : Posts?.map((item) => {
                    return <PostList key={item?._id} item={item} />;
                  })}

              {/* <div className="col-lg-3 col-md-6 col-6 mb-3">
                <div className="post-card">
                  <div className="img-card">
                    <div className="postion-btn">
                      <div className="in-img post-section-img hover-video-btn">
                        <img src={basic} className="img-fluid" alt="" />
                        <div className="heart-rating">
                          <h3>
                            <i class="fa-solid fa-heart"></i>4.2k
                          </h3>
                        </div>
                        <Link to={"/PostAbout"} className="play-btn-hover">
                          <i class="fa-solid fa-eye"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="insure-txt">
                      <h5>Insure the safety of the children </h5>
                      <hr />
                      <div className="graphic-icon">
                        <img
                          src={graphicicon}
                          className="img-fluid"
                          alt="logo"
                        />
                        <h6>Graphiglow design</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-6 mb-3">
                <div className="post-card">
                  <div className="img-card">
                    <div className="postion-btn">
                      <div className="in-img post-section-img hover-video-btn">
                        <img src={basic} className="img-fluid" alt="" />
                        <div className="heart-rating">
                          <h3>
                            <i class="fa-solid fa-heart"></i>4.2k
                          </h3>
                        </div>
                        <Link to={"/VideoPostAbout"} className="play-btn-hover">
                          <i class="fa-solid fa-play"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="insure-txt">
                      <h5>Insure the safety of the children </h5>
                      <hr />
                      <div className="graphic-icon">
                        <img
                          src={graphicicon}
                          className="img-fluid"
                          alt="logo"
                        />
                        <h6>Graphiglow design</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-3">
                <div className="post-card">
                  <div className="img-card">
                    <div className="postion-btn">
                      <div className="in-img post-section-img hover-video-btn">
                        <img src={posttwo} className="img-fluid" alt="" />
                        <div className="heart-rating">
                          <h3>
                            <i class="fa-solid fa-heart"></i>4.2k
                          </h3>
                        </div>
                        <Link to={"/PostPdf"} className="play-btn-hover">
                          <i class="fa-solid fa-eye"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="insure-txt">
                      <h5>Insure the safety of the children </h5>
                      <hr />
                      <div className="graphic-icon">
                        <img
                          src={graphicicon}
                          className="img-fluid"
                          alt="logo"
                        />
                        <h6>Graphiglow design</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-3">
                <div className="post-card">
                  <div className="img-card">
                    <div className="postion-btn">
                      <div className="in-img post-section-img hover-video-btn">
                        <img src={psss} className="img-fluid" alt="" />
                        <div className="heart-rating">
                          <h3>
                            <i class="fa-solid fa-heart"></i>4.2k
                          </h3>
                        </div>
                        <Link to={"/PostPPT"} className="play-btn-hover">
                          <i class="fa-solid fa-eye"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="insure-txt">
                      <h5>Insure the safety of the children </h5>
                      <hr />
                      <div className="graphic-icon">
                        <img
                          src={graphicicon}
                          className="img-fluid"
                          alt="logo"
                        />
                        <h6>Graphiglow design</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-6 mb-3">
                <div className="post-card">
                  <div className="img-card">
                    <div className="postion-btn">
                      <div className="in-img post-section-img hover-video-btn">
                        <img src={postthree} className="img-fluid" alt="" />
                        <div className="heart-rating">
                          <h3>
                            <i class="fa-solid fa-heart"></i>4.2k
                          </h3>
                        </div>
                        <Link to={"/DocPostAbout"} className="play-btn-hover">
                          <i class="fa-solid fa-eye"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="insure-txt">
                      <h5>Insure the safety of the children </h5>
                      <hr />
                      <div className="graphic-icon">
                        <img
                          src={graphicicon}
                          className="img-fluid"
                          alt="logo"
                        />
                        <h6>Graphiglow design</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Post;
