import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "./Header";
import java from "../Image/logo.svg";
import hello from "../Image/doc-post.svg";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { PUTAPI } from "../utils/Integration";
import Api from "../utils/Api";
import useComapany from "../hook/useComapany";
import uploadvideo from "../Image/upload-video-btn.svg";
import { MdCloudDone } from "react-icons/md";

function CompanyProfileEdit() {
  const [cookies, setCookie] = useCookies(["user", "companyImage"]);
  const { register, reset, handleSubmit } = useForm();
  const [video, setvideo] = useState(null);
  const { Company } = useComapany();
  const [image, setImage] = useState(null);
  const [ImageFile, setImageFile] = useState(null);
  const navigate = useNavigate();

  const handleUpdateCompany = (data) => {
    let form_data = new FormData();
    form_data.append("about", data?.about || Company?.about);
    form_data.append("name", Company?.name);
    if (ImageFile) form_data.append("image", ImageFile);
    if (video) form_data.append("video", video);
    toast
      .promise(PUTAPI(Api.Company, form_data, cookies.user), {
        pending: "Wait Few Moment !",
        success: "Company  Update Successfully !",
        error: "Something Wrong !",
      })
      .then((val) => {
        if (val.data) {
          setCookie("companyImage", val.data);
        }
        navigate("/CompanyProfile");
      })
      .catch((er) => {
        console.log(er);
      });
    reset();
  };

  return (
    <div className="asside-two">
      <div className="container-fluid">
        <Header />
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="back-post-section">
              <Link to={"/CompanyProfile"} type="submit">
                {" "}
                <i class="fa-solid fa-arrow-left"></i>{" "}
              </Link>
              <h4>Company Profile</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="profile-section-back-design ">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-12">
              <form
                className="comp-edit"
                onSubmit={handleSubmit(handleUpdateCompany)}
              >
                <div className="setting-click">
                  <h5>Settings</h5>
                </div>
                <div className="my-account">
                  <h4>My Account</h4>

                  <div className="row g-3 my-acc-padding">
                    <div className="profile-upload-img">
                      <img
                        src={image || Company?.image}
                        onError={(e) => (e.currentTarget.src = java)}
                        alt=""
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        id="profile"
                        hidden
                        onChange={(e) => {
                          setImageFile((prev) => e.target.files[0]);
                          setImage(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                      <label htmlFor="profile">
                        <i class="fa-solid fa-pen"></i>
                      </label>
                    </div>

                    <div class="mb-3">
                      <label
                        for="exampleFormControlTextarea1"
                        class="form-label"
                      >
                        About Company:
                      </label>
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="10"
                        defaultValue={Company?.about}
                        {...register("about")}
                      ></textarea>
                    </div>
                    <div className="profile-upload-img-one">
                      <input
                        type="file"
                        accept="video/*"
                        id="videoFile"
                        hidden
                        onChange={(e) => setvideo((prev) => e.target.files[0])}
                      />
                      <div>
                        {video ? (
                          <label htmlFor="profile">
                            <MdCloudDone size={66} color="#23aa2d" />
                          </label>
                        ) : (
                          <label htmlFor="videoFile">
                            <img src={uploadvideo} alt=""></img>
                          </label>
                        )}
                      </div>{" "}
                      <h4>Upload Video Here</h4>
                    </div>
                    <div className="col-12 w-100">
                      <button type="submit" className="w-100">
                        UPDATE
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfileEdit;

// import React from 'react'
// import { Link } from "react-router-dom";
// import Header from './Header'
// import java from '../Image/doc-post.svg'
// import hello from '../Image/doc-post.svg'
// import { useState } from 'react';

// function EditProfile() {
//     const [image, setImage] = useState("")
//     return (
//         <div className='asside-two'>
//             <div className='container-fluid'>
//                 <Header />
//                 <div className='row'>
//                     <div className='col-lg-6 col-md-6 col-12'>
//                         <div className='back-post-section'>
//                             <Link to={'/Profile'} type='submit'> <i class="fa-solid fa-arrow-left"></i> </Link>
//                             <h4>Company Profile</h4>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="container profile-section-back-design ">
//                 <div className="row justify-content-center">
//                     <div className="col-lg-7 col-md-12">
//                         <div className="comp-edit">
//                             <div className="setting-click">
//                                 <h5>Settings</h5>
//                             </div>
//                             <div className="my-account">
//                                 <h4>My Account</h4>
//                                 <div className="profile-upload-img">
//                                     <img src={image} onError={e => e.currentTarget.src = java} alt="" className='img-fluid' />
//                                     <input type="file" id='profile' hidden onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))} />
//                                     <label htmlFor="profile"><i class="fa-solid fa-pen"></i></label>
//                                 </div>
//                                 <div className="row g-3 my-acc-padding">
//                                     <div className="profile-info mb-3">
//                                         <div className='about-cmp'>
//                                             <label for="formGroupExampleInput" class="form-label">About Company :</label>
//                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="6">Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the  Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the </textarea>
//                                         </div>
//                                     </div>
//                                     <div className="col-12">
//                                         <Link to={'#'}>UPDATE   </Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EditProfile
