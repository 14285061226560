import React from "react";
import { formatDate } from "../utils/ExtraFunction";

import img from "../../src/Image/image 1.png";

import img2 from "../Image/img 2.png";
const Certificate = ({ title, user, date }) => {
  return (
    <div
      class="modal fade "
      id="certificate"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog  modal-xl  ">
        <div class="modal-content spl-modal-body">
          {/* <div class="page" id="CertificatePDF">
            <div className="cerBody">
              <div className="border-pattern">
                <div className="content">
                  <div className="inner-content">
                    <h1>Hello</h1>
                    <h2>of Excellence</h2>
                    <h3>This Certificate Is Proudly Presented To</h3>
                    <p>{user}</p>
                    <h3>Has Completed</h3>
                    <p>{title}</p>
                    <h3>On</h3>
                    <p>{formatDate(date)}</p>
                    <div className="badge"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="certificate" id="CertificatePDF">
            <div className="certificate-img">
              <img src={img} alt="img" />
            </div>
            <div className="certificate-img-2">
              <img src={img2} alt="img" />
            </div>

            <div className="certificate-text-top">
              <h4>CERTIFICATE</h4>
              <h5>of Excellence</h5>
            </div>

            <div className="certificate-text-bottom">
              <h4>This Certificate is presented to </h4>
              <h6>{user}</h6>
              <p>
                Has hereby successfully completed a <span>{title} </span>
                training program and he/she very careful to save people and
                he/she successfully completed training
              </p>
            </div>
            <div className="certificate-date">
              <p>{formatDate(date)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
