import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import java from "../Image/doc-post.svg";
import { Link } from "react-router-dom";
import Form from "../common/near/Form";
import { useCookies } from "react-cookie";
import Detail from "../common/near/Detail";

const NearMissMain = () => {
  const [cookie] = useCookies(["role"]);
  return (
    <div>
      <div className="asside">
        <div className="container-fluid">
          <Header />
          <Sidebar />
          {cookie.role == "employee" ? <Form /> : <Detail />}
        </div>
      </div>
    </div>
  );
};

export default NearMissMain;
