import React, { useState } from "react";
import java from "../../Image/doc-post.svg";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { POSTAPI } from "../../utils/Integration";
import Api from "../../utils/Api";
const AddModal = ({ update }) => {
  const [image, setImage] = useState("");
  const [ImageUpload, setImageUpload] = useState(null);
  const [cookie] = useCookies(["user", "companyName"]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleUpdate = (data) => {
    const newForm = new FormData();
    newForm.append("name", data?.name);
    newForm.append("role", data?.role);
    newForm.append("password", data?.password);
    newForm.append("companyName", cookie.companyName);
    newForm.append("location", data?.location);
    newForm.append("designation", data?.designation);
    newForm.append("department", data?.department);
    if (ImageUpload) newForm.append("image", ImageUpload);
    toast
      .promise(POSTAPI(Api.User, newForm, cookie.user), {
        pending: "Wait Few Moment !",
        success: "User Add Successfully !",
        error: "Somthing Wrong !",
      })
      .then(() => {
        update();
      })
      .catch((e) => {
        console.log(e);
      });
    document.getElementById("closeModal").click();
    reset();
    setImage((prev) => "");
    setImageUpload((prev) => null);
  };
  return (
    <div
      class="modal fade "
      id="exampleModalsa"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header border-none">
            <button
              type="button"
              id="closeModal"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form className="comp-edit" onSubmit={handleSubmit(handleUpdate)}>
              <div className="setting-click">
                <h5>Add Employee</h5>
              </div>
              <div className="my-account">
                <h4>My Account</h4>
                <div className="profile-upload-img">
                  <img
                    src={image}
                    onError={(e) => (e.currentTarget.src = java)}
                    alt=""
                    className="img-fluid"
                  />
                  <input
                    type="file"
                    id="profile"
                    hidden
                    onChange={(e) => {
                      setImage(URL.createObjectURL(e.target.files[0]));
                      setImageUpload((prev) => e.target.files[0]);
                    }}
                  />
                  <label htmlFor="profile">
                    <i class="fa-solid fa-pen"></i>
                  </label>
                </div>
                <div className="row g-3 my-acc-padding">
                  <div class="col-md-6 col-12 mb-3">
                    <label for="formGroupExampleInput" class="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Jansh"
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div className="col-md-6 col-12 mb-3">
                    <label htmlFor="formGroupExampleInput" class="form-label">
                      Role
                    </label>
                    <select
                      type="text"
                      class="form-control"
                      aria-label="Last name"
                      {...register("role")}
                    >
                      <option value="employee">Employee</option>
                      <option value="admin">Admin</option>
                    </select>
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="formGroupExampleInput" class="form-label">
                      Destination{" "}
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Destination"
                      {...register("designation", { required: true })}
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <label htmlFor="formGroupExampleInput" class="form-label">
                      Password{" "}
                    </label>
                    <input
                      type="password"
                      class="form-control"
                      placeholder="Destination"
                      {...register("password", { required: true })}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="formGroupExampleInput" class="form-label">
                      Department
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Depatment"
                      {...register("department", { required: true })}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label htmlFor="formGroupExampleInput" class="form-label">
                      Location{" "}
                    </label>
                    <textarea
                      rows="4"
                      type="text"
                      class="form-control"
                      placeholder="Location"
                      {...register("location", { required: true })}
                    />
                  </div>
                  <div className="col-12 w-100">
                    <button type="submit" className="w-100">
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
