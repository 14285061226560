import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Plant from "../Image/plant-location.svg";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import NotFound from "../Image/notfound.svg";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { DELETEAPI, GETAPI } from "../utils/Integration";
import Api from "../utils/Api";
import { MutatingDots } from "react-loader-spinner";
import AddModal from "../common/PlanLayout/AddModal";
import UpdateModal from "../common/PlanLayout/UpdateModal";
import AddPLanLayout from "../common/AddPLanLayout";

const Map = ({ x, y, title }) => {
  return (
    <FaMapMarkerAlt
      title={title}
      color="red"
      style={{
        position: "absolute",
        left: `${x}%`, // Position based on percentage
        top: `${y}%`, // Position based on percentage
        transform: "translate(-50%, -50%)", // Center the point
        width: "2vw",
        height: "2vw",
      }}
    />
  );
};

const PlantLayout = () => {
  const [cookie] = useCookies(["user", "companyName", "role"]);
  const [Point, setPoint] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [Data, setData] = useState([]);
  const [SingleItem, setSingleItem] = useState(null);
  const [isTrue, setisTrue] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);

  const handleAddPoint = (e) => {
    if (!isTrue) return;
    const boxRect = e.target.getBoundingClientRect();
    const x = ((e.clientX - boxRect.left) / boxRect.width) * 100;
    const y = ((e.clientY - boxRect.top) / boxRect.height) * 100;

    setPoint((prev) => ({ x, y }));
    document.getElementById("modalOpen").click();
    setisTrue(false);
  };

  const handleUpdate = () => setisUpdate(!isUpdate);

  const handleDeletePoint = (id) => {
    toast
      .promise(DELETEAPI(`${Api.PlanPoint}/${Data?._id}/${id}`, cookie.user), {
        pending: "Loading...",
        success: "Delete Point Successfully !",
        error: "Something Went Wrong !",
      })
      .then(() => {
        handleUpdate();
      })
      .catch(() => {});
  };

  useEffect(() => {
    (async () => {
      try {
        setisLoad((prev) => true);
        const data = await GETAPI(
          `${Api.PlanLayout}/${cookie.companyName}`,
          cookie.user
        );
        setData(data?.data);
        setisLoad((prev) => false);
      } catch (er) {
        setData(null);
        console.log(er);
      } finally {
        setisLoad((prev) => false);
      }
    })();
  }, [isUpdate]);

  return (
    <div>
      {<Sidebar />}
      {<Header />}

      {/* Add Point Modal  */}
      <AddModal point={Point} id={Data?._id} handleUpdate={handleUpdate} />
      {/* End Add Point Modal  */}
      {/* Edit Point Modal  */}
      <UpdateModal
        id={Data?._id}
        handleUpdate={handleUpdate}
        item={SingleItem}
      />
      {/* End Edit Point Modal  */}

      <div className="asside home-owl">
        <div className="container-fluid">
          {isLoad ? (
            <div
              className="d-flex justify-content-center align-items-center flex-column  "
              style={{ height: "70vh" }}
            >
              <MutatingDots
                visible={true}
                height="100"
                width="100"
                color="#0841D4"
                secondaryColor="#0841D4"
                radius="12.5"
                ariaLabel="mutating-dots-loading"
              />
            </div>
          ) : !Data ? (
            cookie.role != "employee" ? (
              <AddPLanLayout update={handleUpdate} />
            ) : (
              <div className="d-flex justify-content-center  align-items-center  h-100 flex-column ">
                <img src={NotFound} alt="" className="w-25 h-25" />
                <h3>PlanLayout Not Found !</h3>
              </div>
            )
          ) : (
            <div className="row">
              <div className="col-xl-7 col-md-12 mb-2">
                <div className="d-flex align-items-center justify-content-end mb-3">
                  {cookie.role != "employee" && (
                    <div className="view-s-btn">
                      <Link
                        data-bs-toggle="modal"
                        id="modalOpen"
                        data-bs-target="#addpoint"
                        hidden
                      >
                        <i class="fa-solid fa-plus me-2"></i> Add Point
                      </Link>
                      <Link
                        onClick={() => {
                          setisTrue(true);
                        }}
                      >
                        <i class="fa-solid fa-plus me-2"></i> Add Point
                      </Link>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "900px",
                  }}
                >
                  <img
                    src={Data?.image}
                    onError={(e) => (e.currentTarget.src = Plant)}
                    style={{ width: "100%", height: "auto" }}
                    alt=""
                    onClick={handleAddPoint}
                  />
                  {Data?.points &&
                    Data?.points?.map((item) => {
                      return (
                        <Map x={item?.x} y={item?.y} title={item?.title} />
                      );
                    })}
                </div>
                {isTrue && (
                  <div className="error-select-location">
                    <p>Please select your location point !</p>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between my-3">
                  <div className="view-s-btn">
                    <a href={Data?.document} target="_blank">
                      <i class="fa-regular fa-file me-2"></i> View In Doc
                    </a>
                  </div>
                  {cookie.role != "employee" && (
                    <div>
                      <Link
                        to={`/UploadImagePdf/${Data?._id}`}
                        className="emebtnss mt-0"
                      >
                        <i class="fa-solid fa-plus me-2"></i> Update Plan
                        assembly Point
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xl-5 col-md-12">
                <div className="plane-assembly-point">
                  <h2>Plane assembly point</h2>
                  {Data?.points?.map((item) => (
                    <div className="inner-text mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3>{item?.title}</h3>
                        {cookie.role != "employee" && (
                          <div className="firesafety-delete">
                            <Link
                              onClick={() => setSingleItem((prev) => item)}
                              data-bs-toggle="modal"
                              data-bs-target="#editpoint"
                              className="firesafety-edit-icon"
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                            </Link>
                            <Link onClick={() => handleDeletePoint(item?._id)}>
                              <i class="fa-solid fa-trash"></i>
                            </Link>
                          </div>
                        )}
                      </div>
                      <p>{item?.detail}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlantLayout;
