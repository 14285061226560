import React, { useState } from "react";
import { MdCloudDone } from "react-icons/md";
import uploadpdf from "../Image/upload-img.svg";
import uploadpdfc from "../Image/pdf-formate-upload.svg";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { POSTAPI } from "../utils/Integration";
import Api from "../utils/Api";

const AddPLanLayout = ({ update }) => {
  const [document, setdocument] = useState(null);
  const [Image, setImage] = useState(null);
  const [cookie] = useCookies(["companyName", "user"]);
  const handleAddPlan = async () => {
    try {
      if (!Image || !document) toast.error("All Field Must Be Required !");
      const newForm = new FormData();
      newForm.append("CompanyName", cookie.companyName);
      newForm.append("image", Image);
      newForm.append("document", document);
      await toast.promise(POSTAPI(Api.PlanLayout, newForm, cookie.user), {
        pending: "Loading ...",
        success: "PlanLayout Add Successfully !",
        error: "Something Went wrong !",
      });
      update();
    } catch (er) {
      console.log(er);
    }
  };
  return (
    <>
      <div className="back-post-section">
        <h4>Upload Images & PDF Here</h4>
      </div>
      <div className="row justify-content-between ">
        <div className="col-lg-12 col-md-12">
          <div className="commen-layout-upload">
            <div className="profile-upload-img-one mb-4">
              <input
                type="file"
                id="profile"
                hidden
                onChange={(e) => {
                  setImage((prev) => e.target.files[0]);
                }}
              />
              {Image ? (
                <label htmlFor="profile">
                  <MdCloudDone size={66} color="#23aa2d" />
                </label>
              ) : (
                <div>
                  <label htmlFor="profile">
                    <img src={uploadpdf} alt=""></img>
                  </label>
                </div>
              )}
              <h4>Upload Post Here</h4>
            </div>
            <div className="profile-upload-img-two">
              <div className="pdf-pic">
                <img src={uploadpdfc} alt=""></img>
              </div>
              <label htmlFor="pdf" className="uplaod-pdf p-0 bg-transparent">
                <input
                  type="file"
                  name=""
                  id="pdf"
                  hidden
                  onChange={(e) => setdocument((prev) => e.target.files[0])}
                />
                <div
                  className={`btn btn-upload-pdf ${
                    document && "text-bg-success "
                  }`}
                >
                  Upload PDF
                </div>
              </label>
            </div>
            <div class="col-12 mt-3">
              <button
                type="submit"
                class=" update-plan-btn"
                onClick={handleAddPlan}
              >
                Add Plan assembly Point
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPLanLayout;
